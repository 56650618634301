import axios from "axios";
import apiConfig from "../configuration/api-config";

axios.defaults.baseURL = `${apiConfig.apiBaseUrl}`;
axios.defaults.headers.post["Content-Type"] = "application/json";

const wsBaseUrl = `/Catalogs.svc/json/`;

var wsResponse = {
  result: false,
  response: null,
};

async function SAT_Productos_Lista(
  devicekey,
  typeDevice,
  admixProduct,
  fechaInicial,
  fechaFinal,
  idEmpresa,
  idUser,
  numItems,
  numItemsLoaded,
  id = 0,
  search = null
) {
  const url = `${wsBaseUrl}SAT_Productos_Lista/`;

  //#region Validaciones
  if (!devicekey) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado una llave de dispositivo.",
          Solution: "Verifique la conección a la API de Admix.",
          Type: 1,
        },
      },
    };
  }

  if (!typeDevice) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de dispositivo.",
          Solution: "Agrega un tipo de dispositivo disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (typeDevice >= 0 && typeDevice <= 10) ||
      typeDevice == 100 ||
      typeDevice == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de dispositivo no esta permitido.",
          Solution: "Verifica la lista de dispositivos disponibles.",
          Type: 1,
        },
      },
    };
  }

  if (!admixProduct) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de aplicacion.",
          Solution: "Agrega un tipo de producto de Admix disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (admixProduct >= -1 && admixProduct <= 16) ||
      admixProduct == 100 ||
      admixProduct == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de aplicacion no esta permitida.",
          Solution: "Verifica la lista de productos de Admix disponibles.",
          Type: 1,
        },
      },
    };
  }
  //#endregion

  // Formar el Request con los datos de la peticion
  let request = {
    Request: {
      DeviceKey: devicekey,
      AdmixProduct: admixProduct,
      TypeDevice: typeDevice,
      Id: id,
      IdEmpresa: idEmpresa,
      FechaInicial: fechaInicial,
      FechaFinal: fechaFinal,
      Search: search,
      NumItemsLoaded: numItemsLoaded,
      NumItems: numItems,
      IdUser: idUser,
    },
  };

  console.log(request);
  try {
    //Enviar Peticion
    var res = await axios.post(url, request);

    //Regresar Respuesta obtenida de la variable data
    return {
      result: res.data.SAT_Productos_ListaResult,
      response: res.data.Response,
    };
  } catch (error) {
    wsResponse.response = {
      Error: {
        Code: "WSC01",
        Message: error,
        Solution: "Verifique la conección a la API de Admix.",
        Type: 1,
      },
    };
    return wsResponse;
  }
}

async function SAT_Productos_Propios_Lista(
  devicekey,
  typeDevice,
  admixProduct,
  idEmpresa,
  idUser,
  numItems,
  numItemsLoaded,
  id,
  search
) {
  const url = `${wsBaseUrl}SAT_Productos_Propios_Lista/`;

  //#region Validaciones
  if (!devicekey) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado una llave de dispositivo.",
          Solution: "Verifique la conección a la API de Admix.",
          Type: 1,
        },
      },
    };
  }

  if (!typeDevice) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de dispositivo.",
          Solution: "Agrega un tipo de dispositivo disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (typeDevice >= 0 && typeDevice <= 10) ||
      typeDevice == 100 ||
      typeDevice == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de dispositivo no esta permitido.",
          Solution: "Verifica la lista de dispositivos disponibles.",
          Type: 1,
        },
      },
    };
  }

  if (!admixProduct) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de aplicacion.",
          Solution: "Agrega un tipo de producto de Admix disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (admixProduct >= -1 && admixProduct <= 16) ||
      admixProduct == 100 ||
      admixProduct == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de aplicacion no esta permitida.",
          Solution: "Verifica la lista de productos de Admix disponibles.",
          Type: 1,
        },
      },
    };
  }
  //#endregion

  // Formar el Request con los datos de la peticion
  let request = {
    Request: {
      DeviceKey: devicekey,
      AdmixProduct: admixProduct,
      TypeDevice: typeDevice,
      Id: id,
      IdEmpresa: idEmpresa,
      IdUser: idUser,
      NumItems: numItems,
      NumItemsLoaded: numItemsLoaded,
      Search: search,
    },
  };

  try {
    //Enviar Peticion
    var res = await axios.post(url, request);

    //Regresar Respuesta obtenida de la variable data
    return {
      result: res.data.SAT_Productos_Propios_ListaResult,
      response: res.data.Response,
    };
  } catch (error) {
    wsResponse.response = {
      Error: {
        Code: "WSC01",
        Message: error,
        Solution: "Verifique la conección a la API de Admix.",
        Type: 1,
      },
    };
    return wsResponse;
  }
}

async function SAT_Productos_Widget_ProductosPropiosEncontrados(
  devicekey,
  typeDevice,
  admixProduct,
  idEmpresa,
  idUser,
  numItems,
  numItemsLoaded,
  search
) {
  const url = `${wsBaseUrl}SAT_Productos_Widget_ProductosPropiosEncontrados/`;

  //#region Validaciones
  if (!devicekey) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado una llave de dispositivo.",
          Solution: "Verifique la conección a la API de Admix.",
          Type: 1,
        },
      },
    };
  }

  if (!typeDevice) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de dispositivo.",
          Solution: "Agrega un tipo de dispositivo disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (typeDevice >= 0 && typeDevice <= 10) ||
      typeDevice == 100 ||
      typeDevice == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de dispositivo no esta permitido.",
          Solution: "Verifica la lista de dispositivos disponibles.",
          Type: 1,
        },
      },
    };
  }

  if (!admixProduct) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de aplicacion.",
          Solution: "Agrega un tipo de producto de Admix disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (admixProduct >= -1 && admixProduct <= 16) ||
      admixProduct == 100 ||
      admixProduct == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de aplicacion no esta permitida.",
          Solution: "Verifica la lista de productos de Admix disponibles.",
          Type: 1,
        },
      },
    };
  }
  //#endregion

  // Formar el Request con los datos de la peticion
  let request = {
    Request: {
      DeviceKey: devicekey,
      AdmixProduct: admixProduct,
      TypeDevice: typeDevice,
      IdEmpresa: idEmpresa,
      IdUser: idUser,
      NumItems: numItems,
      NumItemsLoaded: numItemsLoaded,
      Search: search,
    },
  };

  try {
    //Enviar Peticion
    var res = await axios.post(url, request);

    //Regresar Respuesta obtenida de la variable data
    return {
      result: res.data.SAT_Productos_Widget_ProductosPropiosEncontradosResult,
      response: res.data.Response,
    };
  } catch (error) {
    wsResponse.response = {
      Error: {
        Code: "WSC01",
        Message: error,
        Solution: "Verifique la conección a la API de Admix.",
        Type: 1,
      },
    };
    return wsResponse;
  }
}

async function SAT_Productos_Propios_Lista_Top5PorCantidad(
  devicekey,
  typeDevice,
  admixProduct,
  id,
  idEmpresa,
  idUser,
  numItems,
  numItemsLoaded,
  search
) {
  const url = `${wsBaseUrl}SAT_Productos_Propios_Lista_Top5PorCantidad/`;

  //#region Validaciones
  if (!devicekey) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado una llave de dispositivo.",
          Solution: "Verifique la conección a la API de Admix.",
          Type: 1,
        },
      },
    };
  }

  if (!typeDevice) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de dispositivo.",
          Solution: "Agrega un tipo de dispositivo disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (typeDevice >= 0 && typeDevice <= 10) ||
      typeDevice == 100 ||
      typeDevice == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de dispositivo no esta permitido.",
          Solution: "Verifica la lista de dispositivos disponibles.",
          Type: 1,
        },
      },
    };
  }

  if (!admixProduct) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de aplicacion.",
          Solution: "Agrega un tipo de producto de Admix disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (admixProduct >= -1 && admixProduct <= 16) ||
      admixProduct == 100 ||
      admixProduct == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de aplicacion no esta permitida.",
          Solution: "Verifica la lista de productos de Admix disponibles.",
          Type: 1,
        },
      },
    };
  }
  //#endregion

  // Formar el Request con los datos de la peticion
  let request = {
    Request: {
      DeviceKey: devicekey,
      AdmixProduct: admixProduct,
      TypeDevice: typeDevice,
      Id: id,
      IdEmpresa: idEmpresa,
      IdUser: idUser,
      NumItems: numItems,
      NumItemsLoaded: numItemsLoaded,
      Search: search,
    },
  };

  try {
    //Enviar Peticion
    var res = await axios.post(url, request);

    //Regresar Respuesta obtenida de la variable data
    return {
      result: res.data.SAT_Productos_Propios_Lista_Top5PorCantidadResult,
      response: res.data.Response,
    };
  } catch (error) {
    wsResponse.response = {
      Error: {
        Code: "WSC01",
        Message: error,
        Solution: "Verifique la conección a la API de Admix.",
        Type: 1,
      },
    };
    return wsResponse;
  }
}

async function SAT_Productos_Propios_Lista_Top5PorIngreso(
  devicekey,
  typeDevice,
  admixProduct,
  id,
  idEmpresa,
  idUser,
  numItems,
  numItemsLoaded,
  search
) {
  const url = `${wsBaseUrl}SAT_Productos_Propios_Lista_Top5PorIngreso/`;

  //#region Validaciones
  if (!devicekey) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado una llave de dispositivo.",
          Solution: "Verifique la conección a la API de Admix.",
          Type: 1,
        },
      },
    };
  }

  if (!typeDevice) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de dispositivo.",
          Solution: "Agrega un tipo de dispositivo disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (typeDevice >= 0 && typeDevice <= 10) ||
      typeDevice == 100 ||
      typeDevice == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de dispositivo no esta permitido.",
          Solution: "Verifica la lista de dispositivos disponibles.",
          Type: 1,
        },
      },
    };
  }

  if (!admixProduct) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de aplicacion.",
          Solution: "Agrega un tipo de producto de Admix disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (admixProduct >= -1 && admixProduct <= 16) ||
      admixProduct == 100 ||
      admixProduct == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de aplicacion no esta permitida.",
          Solution: "Verifica la lista de productos de Admix disponibles.",
          Type: 1,
        },
      },
    };
  }
  //#endregion

  // Formar el Request con los datos de la peticion
  let request = {
    Request: {
      DeviceKey: devicekey,
      AdmixProduct: admixProduct,
      TypeDevice: typeDevice,
      Id: id,
      IdEmpresa: idEmpresa,
      IdUser: idUser,
      NumItems: numItems,
      NumItemsLoaded: numItemsLoaded,
      Search: search,
    },
  };

  try {
    //Enviar Peticion
    var res = await axios.post(url, request);

    //Regresar Respuesta obtenida de la variable data
    return {
      result: res.data.SAT_Productos_Propios_Lista_Top5PorIngresoResult,
      response: res.data.Response,
    };
  } catch (error) {
    wsResponse.response = {
      Error: {
        Code: "WSC01",
        Message: error,
        Solution: "Verifique la conección a la API de Admix.",
        Type: 1,
      },
    };
    return wsResponse;
  }
}

async function SAT_Productos_Adquiridos_Lista(
  devicekey,
  typeDevice,
  admixProduct,
  idEmpresa,
  idUser,
  numItems,
  numItemsLoaded,
  id,
  search
) {
  const url = `${wsBaseUrl}SAT_Productos_Adquiridos_Lista/`;

  //#region Validaciones
  if (!devicekey) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado una llave de dispositivo.",
          Solution: "Verifique la conección a la API de Admix.",
          Type: 1,
        },
      },
    };
  }

  if (!typeDevice) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de dispositivo.",
          Solution: "Agrega un tipo de dispositivo disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (typeDevice >= 0 && typeDevice <= 10) ||
      typeDevice == 100 ||
      typeDevice == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de dispositivo no esta permitido.",
          Solution: "Verifica la lista de dispositivos disponibles.",
          Type: 1,
        },
      },
    };
  }

  if (!admixProduct) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de aplicacion.",
          Solution: "Agrega un tipo de producto de Admix disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (admixProduct >= -1 && admixProduct <= 16) ||
      admixProduct == 100 ||
      admixProduct == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de aplicacion no esta permitida.",
          Solution: "Verifica la lista de productos de Admix disponibles.",
          Type: 1,
        },
      },
    };
  }
  //#endregion

  // Formar el Request con los datos de la peticion
  let request = {
    Request: {
      DeviceKey: devicekey,
      AdmixProduct: admixProduct,
      TypeDevice: typeDevice,
      Id: id,
      IdEmpresa: idEmpresa,
      IdUser: idUser,
      NumItems: numItems,
      NumItemsLoaded: numItemsLoaded,
      Search: search,
    },
  };

  try {
    //Enviar Peticion
    var res = await axios.post(url, request);

    //Regresar Respuesta obtenida de la variable data
    return {
      result: res.data.SAT_Productos_Adquiridos_ListaResult,
      response: res.data.Response,
    };
  } catch (error) {
    wsResponse.response = {
      Error: {
        Code: "WSC01",
        Message: error,
        Solution: "Verifique la conección a la API de Admix.",
        Type: 1,
      },
    };
    return wsResponse;
  }
}

async function SAT_Productos_Widget_ProductosAdquiridosEncontrados(
  devicekey,
  typeDevice,
  admixProduct,
  idEmpresa,
  idUser,
  numItems,
  numItemsLoaded,
  search
) {
  const url = `${wsBaseUrl}SAT_Productos_Widget_ProductosAdquiridosEncontrados/`;

  //#region Validaciones
  if (!devicekey) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado una llave de dispositivo.",
          Solution: "Verifique la conección a la API de Admix.",
          Type: 1,
        },
      },
    };
  }

  if (!typeDevice) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de dispositivo.",
          Solution: "Agrega un tipo de dispositivo disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (typeDevice >= 0 && typeDevice <= 10) ||
      typeDevice == 100 ||
      typeDevice == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de dispositivo no esta permitido.",
          Solution: "Verifica la lista de dispositivos disponibles.",
          Type: 1,
        },
      },
    };
  }

  if (!admixProduct) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de aplicacion.",
          Solution: "Agrega un tipo de producto de Admix disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (admixProduct >= -1 && admixProduct <= 16) ||
      admixProduct == 100 ||
      admixProduct == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de aplicacion no esta permitida.",
          Solution: "Verifica la lista de productos de Admix disponibles.",
          Type: 1,
        },
      },
    };
  }
  //#endregion

  // Formar el Request con los datos de la peticion
  let request = {
    Request: {
      DeviceKey: devicekey,
      AdmixProduct: admixProduct,
      TypeDevice: typeDevice,
      IdEmpresa: idEmpresa,
      IdUser: idUser,
      NumItems: numItems,
      NumItemsLoaded: numItemsLoaded,
      Search: search,
    },
  };

  try {
    //Enviar Peticion
    var res = await axios.post(url, request);

    //Regresar Respuesta obtenida de la variable data
    return {
      result:
        res.data.SAT_Productos_Widget_ProductosAdquiridosEncontradosResult,
      response: res.data.Response,
    };
  } catch (error) {
    wsResponse.response = {
      Error: {
        Code: "WSC01",
        Message: error,
        Solution: "Verifique la conección a la API de Admix.",
        Type: 1,
      },
    };
    return wsResponse;
  }
}

async function SAT_Productos_Adquiridos_Lista_Top5PorCantidad(
  devicekey,
  typeDevice,
  admixProduct,
  id,
  idEmpresa,
  idUser,
  numItems,
  numItemsLoaded,
  search
) {
  const url = `${wsBaseUrl}SAT_Productos_Adquiridos_Lista_Top5PorCantidad/`;

  //#region Validaciones
  if (!devicekey) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado una llave de dispositivo.",
          Solution: "Verifique la conección a la API de Admix.",
          Type: 1,
        },
      },
    };
  }

  if (!typeDevice) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de dispositivo.",
          Solution: "Agrega un tipo de dispositivo disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (typeDevice >= 0 && typeDevice <= 10) ||
      typeDevice == 100 ||
      typeDevice == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de dispositivo no esta permitido.",
          Solution: "Verifica la lista de dispositivos disponibles.",
          Type: 1,
        },
      },
    };
  }

  if (!admixProduct) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de aplicacion.",
          Solution: "Agrega un tipo de producto de Admix disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (admixProduct >= -1 && admixProduct <= 16) ||
      admixProduct == 100 ||
      admixProduct == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de aplicacion no esta permitida.",
          Solution: "Verifica la lista de productos de Admix disponibles.",
          Type: 1,
        },
      },
    };
  }
  //#endregion

  // Formar el Request con los datos de la peticion
  let request = {
    Request: {
      DeviceKey: devicekey,
      AdmixProduct: admixProduct,
      TypeDevice: typeDevice,
      Id: id,
      IdEmpresa: idEmpresa,
      IdUser: idUser,
      NumItems: numItems,
      NumItemsLoaded: numItemsLoaded,
      Search: search,
    },
  };

  try {
    //Enviar Peticion
    var res = await axios.post(url, request);

    //Regresar Respuesta obtenida de la variable data
    return {
      result: res.data.SAT_Productos_Adquiridos_Lista_Top5PorCantidadResult,
      response: res.data.Response,
    };
  } catch (error) {
    wsResponse.response = {
      Error: {
        Code: "WSC01",
        Message: error,
        Solution: "Verifique la conección a la API de Admix.",
        Type: 1,
      },
    };
    return wsResponse;
  }
}

async function SAT_Productos_Adquiridos_Lista_Top5PorIngreso(
  devicekey,
  typeDevice,
  admixProduct,
  id,
  idEmpresa,
  idUser,
  numItems,
  numItemsLoaded,
  search
) {
  const url = `${wsBaseUrl}SAT_Productos_Adquiridos_Lista_Top5PorIngreso/`;

  //#region Validaciones
  if (!devicekey) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado una llave de dispositivo.",
          Solution: "Verifique la conección a la API de Admix.",
          Type: 1,
        },
      },
    };
  }

  if (!typeDevice) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de dispositivo.",
          Solution: "Agrega un tipo de dispositivo disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (typeDevice >= 0 && typeDevice <= 10) ||
      typeDevice == 100 ||
      typeDevice == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de dispositivo no esta permitido.",
          Solution: "Verifica la lista de dispositivos disponibles.",
          Type: 1,
        },
      },
    };
  }

  if (!admixProduct) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de aplicacion.",
          Solution: "Agrega un tipo de producto de Admix disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (admixProduct >= -1 && admixProduct <= 16) ||
      admixProduct == 100 ||
      admixProduct == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de aplicacion no esta permitida.",
          Solution: "Verifica la lista de productos de Admix disponibles.",
          Type: 1,
        },
      },
    };
  }
  //#endregion

  // Formar el Request con los datos de la peticion
  let request = {
    Request: {
      DeviceKey: devicekey,
      AdmixProduct: admixProduct,
      TypeDevice: typeDevice,
      Id: id,
      IdEmpresa: idEmpresa,
      IdUser: idUser,
      NumItems: numItems,
      NumItemsLoaded: numItemsLoaded,
      Search: search,
    },
  };

  try {
    //Enviar Peticion
    var res = await axios.post(url, request);

    //Regresar Respuesta obtenida de la variable data
    return {
      result: res.data.SAT_Productos_Adquiridos_Lista_Top5PorIngresoResult,
      response: res.data.Response,
    };
  } catch (error) {
    wsResponse.response = {
      Error: {
        Code: "WSC01",
        Message: error,
        Solution: "Verifique la conección a la API de Admix.",
        Type: 1,
      },
    };
    return wsResponse;
  }
}

export default {
  SAT_Productos_Lista,
  SAT_Productos_Propios_Lista,
  SAT_Productos_Widget_ProductosPropiosEncontrados,
  SAT_Productos_Propios_Lista_Top5PorCantidad,
  SAT_Productos_Propios_Lista_Top5PorIngreso,
  SAT_Productos_Adquiridos_Lista,
  SAT_Productos_Widget_ProductosAdquiridosEncontrados,
  SAT_Productos_Adquiridos_Lista_Top5PorCantidad,
  SAT_Productos_Adquiridos_Lista_Top5PorIngreso,
};
