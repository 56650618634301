<template>
  <div
    class="absolute right-0 z-10 mt-2 w-96 divide-gray-100 dark:divide-gray-700 rounded-md bg-white dark:bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
    role="menu"
    aria-orientation="vertical"
    aria-labelledby="menu-button"
    tabindex="-1"
  >
    <div
      class="py-3 px-4 cursor-pointer text-gray-900 bg-slate-200 dark:text-gray-400 dark:bg-gray-700 rounded-t-lg"
    >
      Ultimas notificaciones
    </div>
    <!-- With not content  -->
    <div
      v-show="notifications.length == 0"
      class="flex flex-col items-center justify-center h-96 text-center"
    >
      <div
        class="relative inline-flex items-center justify-center w-12 h-12 overflow-hidden text-blue-400 bg-blue-50 dark:bg-gray-900 rounded-full"
      >
        <svg
          class="w-6 h-6"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path
            d="M22 17H2a3 3 0 0 0 3-3V9a7 7 0 0 1 14 0v5a3 3 0 0 0 3 3zm-8.27 4a2 2 0 0 1-3.46 0"
          ></path>
        </svg>
      </div>
      <h1 class="text-sm font-semibold text-gray-700 dark:text-gray-200 pt-6">
        No hay notificaciones
      </h1>
      <p class="text-sm text-gray-500 dark:text-gray-400">
        No hay nuevas notificaciones por el momento. <br />
        ¡Vuelve pronto para ver las últimas actualizaciones!
      </p>
    </div>
    <notification-item-template
      v-for="not in notifications"
      :key="not.id"
      :notification="not"
    />
    <hr class="border-gray-100 dark:border-gray-700" />
    <div class="flex justify-center items-center">
      <button
        id="openSelectCompanyBox"
        class="py-4 px-4 cursor-pointer text-gray-700 dark:text-gray-200 text-sm"
      >
        Ver todas las notificaciones
      </button>
      <svg
        class="w-6 h-6"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="M9 18l6-6-6-6" />
      </svg>
    </div>
  </div>
</template>
<script setup>
import NotificationItemTemplate from "./NotificationItemTemplate.vue";
import { toRefs, defineProps } from "vue";
const props = defineProps({
  notifications: {
    type: Array,
    default: () => [
      {
        id: 1,
        description:
          "La descarga programada de XMLs se ha realizado correctamente.",
        isNew: true,
        typeN: 1,
        date: "2024-03-21",
      },
      {
        id: 2,
        description: "La descarga programada de XMLs no se pudo realizar.",
        isNew: true,
        typeN: 0,
        date: "2024-03-20",
      },
      {
        id: 3,
        description:
          "La sincronización programada con el buzón tributario se ha realizado correctamente.",
        isNew: false,
        typeN: 4,
        date: "2024-03-15",
      },
      {
        id: 4,
        description:
          "La descarga programada de la CSF se ha realizado correctamente.",
        isNew: false,
        typeN: 2,
        date: "2024-03-12",
      },
      {
        id: 5,
        description: "La Firma Electrónica de la empresa caducará en 15 días.",
        isNew: false,
        typeN: 3,
        date: "2024-03-12",
      },
    ],
  },
});

const { notifications } = toRefs(props);
</script>
