import axios from "axios";
import apiConfig from "../configuration/api-config";

axios.defaults.baseURL = `${apiConfig.apiBaseUrl}`;
axios.defaults.headers.post["Content-Type"] = "application/json";

const wsBaseUrl = `/LogOn.svc/json/`;

var wsResponse = {
  result: false,
  response: null,
};

async function LoginA(devicekey, username, password) {
  const url = `${wsBaseUrl}Login/`;

  let request = {
    Request: {
      UserName: username,
      Password: password,
      DeviceKey: devicekey,
      TypeDevice: 1,
      AdmixProduct: 1,
    },
  };

  // let post = {
  //     method: 'POST',
  //     body: JSON.stringify(request),
  //     headers: {
  //     'Content-Type': 'application/json'
  //     }
  // };

  try {
    //var response = await fetch(url, post, { mode: 'cors' });

    var response = await axios.post(url, request);
    // console.log(response);
    return {
      result: response.data.LoginResult,
      response: response.data.Response,
    };
  } catch (error) {
    wsResponse.response = {
      Error: {
        Code: "WSC01",
        Message: error,
        Solution: "Verifique la conección a la API de Admix.",
        Type: 1,
      },
    };
    return wsResponse;
  }
}

async function Login_SessionCode_Generate(
  macAddress_NoApp,
  username,
  password,
  typeDevice,
  admixProduct
) {
  const url = `${wsBaseUrl}Login_SessionCode_Generate/`;

  //#region Validaciones
  if (!macAddress_NoApp) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado la direccion MAC del dispositivo.",
          Solution: "Agrega la direccion MAC del dispositivo.",
          Type: 1,
        },
      },
    };
  }

  if (!username) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el usuario.",
          Solution: "Agrega una cuenta de usuario.",
          Type: 1,
        },
      },
    };
  }

  if (!password) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado la contraseña del usuario.",
          Solution: "Agrega la contraseña del usuario especificado.",
          Type: 1,
        },
      },
    };
  }

  if (!typeDevice) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de dispositivo.",
          Solution: "Agrega un tipo de dispositivo disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (typeDevice >= 0 && typeDevice <= 10) ||
      typeDevice == 100 ||
      typeDevice == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de dispositivo no esta permitido.",
          Solution: "Verifica la lista de dispositivos disponibles.",
          Type: 1,
        },
      },
    };
  }

  if (!admixProduct) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de aplicacion.",
          Solution: "Agrega un tipo de producto de Admix disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (admixProduct >= -1 && admixProduct <= 16) ||
      admixProduct == 100 ||
      admixProduct == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de aplicacion no esta permitida.",
          Solution: "Verifica la lista de productos de Admix disponibles.",
          Type: 1,
        },
      },
    };
  }
  //#endregion

  // Formar el Request con los datos de la peticion
  let request = {
    Request: {
      MACAddress_NoApp: macAddress_NoApp,
      UserName: username,
      Password: password,
      IpAddress: apiConfig.wsIpKey,
      DeviceName: apiConfig.wsNameKey,
      TypeDevice: typeDevice,
      AdmixProduct: admixProduct,
    },
  };

  try {
    //Enviar Peticion
    var res = await axios.post(url, request);

    //Regresar Respuesta obtenida de la variable data
    return {
      result: res.data.Login_SessionCode_GenerateResult,
      response: res.data.Response,
    };
  } catch (error) {
    wsResponse.response = {
      Error: {
        Code: "WSC01",
        Message: error,
        Solution: "Verifique la conección a la API de Admix.",
        Type: 1,
      },
    };
    return wsResponse;
  }
}

async function Login_SessionCode(code, typeDevice, admixProduct) {
  const url = `${wsBaseUrl}Login_SessionCode/`;

  //#region Validaciones
  if (!code) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el codigo de sesión.",
          Solution: "Revisa tu cuenta de correo.",
          Type: 1,
        },
      },
    };
  }

  if (!typeDevice) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de dispositivo.",
          Solution: "Agrega un tipo de dispositivo disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (typeDevice >= 0 && typeDevice <= 10) ||
      typeDevice == 100 ||
      typeDevice == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de dispositivo no esta permitido.",
          Solution: "Verifica la lista de dispositivos disponibles.",
          Type: 1,
        },
      },
    };
  }

  if (!admixProduct) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de aplicacion.",
          Solution: "Agrega un tipo de producto de Admix disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (admixProduct >= -1 && admixProduct <= 16) ||
      admixProduct == 100 ||
      admixProduct == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de aplicacion no esta permitida.",
          Solution: "Verifica la lista de productos de Admix disponibles.",
          Type: 1,
        },
      },
    };
  }
  //#endregion

  // Formar el Request con los datos de la peticion
  let request = {
    Request: {
      Code: code,
      IpAddress: apiConfig.wsIpKey,
      DeviceName: apiConfig.wsNameKey,
      TypeDevice: typeDevice,
      AdmixProduct: admixProduct,
    },
  };

  try {
    //Enviar Peticion
    var res = await axios.post(url, request);

    //Regresar Respuesta obtenida de la variable data
    return {
      result: res.data.Login_SessionCodeResult,
      response: res.data.Response,
    };
  } catch (error) {
    wsResponse.response = {
      Error: {
        Code: "WSC01",
        Message: error,
        Solution: "Verifique la conección a la API de Admix.",
        Type: 1,
      },
    };
    return wsResponse;
  }
}

async function Login_Subdomain_Admin(
  username,
  password,
  typeDevice,
  admixProduct
) {
  // ${wsBaseUrl}
  const url = `${wsBaseUrl}Login_Subdomain_Admin/`;

  //#region Validaciones
  if (!username) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el usuario.",
          Solution: "Agrega una cuenta de usuario.",
          Type: 1,
        },
      },
    };
  }

  if (!password) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado la contraseña del usuario.",
          Solution: "Agrega la contraseña del usuario especificado.",
          Type: 1,
        },
      },
    };
  }

  if (!typeDevice) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de dispositivo.",
          Solution: "Agrega un tipo de dispositivo disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (typeDevice >= 0 && typeDevice <= 10) ||
      typeDevice == 100 ||
      typeDevice == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de dispositivo no esta permitido.",
          Solution: "Verifica la lista de dispositivos disponibles.",
          Type: 1,
        },
      },
    };
  }

  if (!admixProduct) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de aplicacion.",
          Solution: "Agrega un tipo de producto de Admix disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (admixProduct >= -1 && admixProduct <= 16) ||
      admixProduct == 100 ||
      admixProduct == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de aplicacion no esta permitida.",
          Solution: "Verifica la lista de productos de Admix disponibles.",
          Type: 1,
        },
      },
    };
  }
  //#endregion

  // Formar el Request con los datos de la peticion
  let request = {
    Request: {
      UserName: username,
      Password: password,
      IpAddress: apiConfig.wsIpKey,
      DeviceName: apiConfig.wsNameKey,
      TypeDevice: typeDevice,
      AdmixProduct: admixProduct,
    },
  };

  try {
    //Enviar Peticion
    var res = await axios.post(url, request);
    //Regresar Respuesta obtenida de la variable data
    return {
      result: res.data.Login_Subdomain_AdminResult,
      response: res.data.Response,
    };
  } catch (error) {
    wsResponse.response = {
      Error: {
        Code: "WSC01",
        Message: error,
        Solution: "Verifique la conección a la API de Admix.",
        Type: 1,
      },
    };
    return wsResponse;
  }
}

async function Login_Subdomain(
  username,
  password,
  subdominio,
  typeDevice,
  admixProduct
) {
  // ${wsBaseUrl}
  const url = `${wsBaseUrl}Login_Subdomain/`;
  // console.log(apiConfig.apiBaseUrl);
  //#region Validaciones
  if (!username) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el usuario.",
          Solution: "Agrega una cuenta de usuario.",
          Type: 1,
        },
      },
    };
  }

  if (!password) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado la contraseña del usuario.",
          Solution: "Agrega la contraseña del usuario especificado.",
          Type: 1,
        },
      },
    };
  }

  if (!typeDevice) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de dispositivo.",
          Solution: "Agrega un tipo de dispositivo disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (typeDevice >= 0 && typeDevice <= 10) ||
      typeDevice == 100 ||
      typeDevice == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de dispositivo no esta permitido.",
          Solution: "Verifica la lista de dispositivos disponibles.",
          Type: 1,
        },
      },
    };
  }

  if (!admixProduct) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de aplicacion.",
          Solution: "Agrega un tipo de producto de Admix disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (admixProduct >= -1 && admixProduct <= 16) ||
      admixProduct == 100 ||
      admixProduct == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de aplicacion no esta permitida.",
          Solution: "Verifica la lista de productos de Admix disponibles.",
          Type: 1,
        },
      },
    };
  }
  //#endregion

  // Formar el Request con los datos de la peticion
  let request = {
    Request: {
      Subdominio: subdominio,
      UserName: username,
      Password: password,
      IpAddress: apiConfig.wsIpKey,
      DeviceName: apiConfig.wsNameKey,
      TypeDevice: typeDevice,
      AdmixProduct: admixProduct,
    },
  };

  try {
    //Enviar Peticion
    var res = await axios.post(url, request);
    //Regresar Respuesta obtenida de la variable data
    return {
      result: res.data.Login_SubdomainResult,
      response: res.data.Response,
    };
  } catch (error) {
    wsResponse.response = {
      Error: {
        Code: "WSC01",
        Message: error,
        Solution: "Verifique la conección a la API de Admix.",
        Type: 1,
      },
    };
    return wsResponse;
  }
}

async function Login(devicekey, username, password, typeDevice, admixProduct) {
  const url = `${wsBaseUrl}Login/`;

  //#region Validaciones
  if (!devicekey) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado una llave de dispositivo.",
          Solution: "Verifique la conección a la API de Admix.",
          Type: 1,
        },
      },
    };
  }

  if (!username) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el usuario.",
          Solution: "Verifique la conección a la API de Admix.",
          Type: 1,
        },
      },
    };
  }

  if (!password) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado la contraseña del usuario.",
          Solution: "Verifique la conección a la API de Admix.",
          Type: 1,
        },
      },
    };
  }
  //#endregion

  // Formar el Request con los datos de la peticion
  let request = {
    Request: {
      UserName: username,
      Password: password,
      DeviceKey: devicekey,
      TypeDevice: typeDevice,
      AdmixProduct: admixProduct,
    },
  };

  try {
    // Enviar Peticion
    var res = await axios.post(url, request);

    //Regresar Respuesta obtenida de la variable data
    return {
      result: res.data.LoginResult,
      response: res.data.Response,
    };
  } catch (error) {
    wsResponse.response = {
      Error: {
        Code: "WSC01",
        Message: error,
        Solution: "Verifique la conección a la API de Admix.",
        Type: 1,
      },
    };
    return wsResponse;
  }
}

export default {
  LoginA,

  Login_SessionCode_Generate,
  Login_SessionCode,

  Login_Subdomain_Admin,
  Login_Subdomain,
  Login,
};
