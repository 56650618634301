<template>
  <div
    class="relative inline-flex items-center justify-center w-12 h-12 overflow-hidden text-green-400 bg-teal-50 dark:bg-gray-900 rounded-full"
  >
    <svg
      class="w-5 h-5 mx-1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path
        d="M14 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V8l-6-6z"
      />
      <path d="M14 3v5h5M16 13H8M16 17H8M10 9H8" />
    </svg>
  </div>
</template>
