import wsCatalogs from "./modules/wsCatalogs";
import wsCFDi from "./modules/wsCFDi";
import wsCompany from "./modules/wsCompany";
import wsCustomers from "./modules/wsCustomers";
import wsKernel from "./modules/wsKernel";
import wsLogOn from "./modules/wsLogOn";
import wsManagement from "./modules/wsManagement";
import wsProducts from "./modules/wsProducts";
import wsProviders from "./modules/wsProviders";

export default {
  wsCompany,
  wsLogOn,
  wsManagement,
  wsKernel,
  wsProducts,
  wsCustomers,
  wsProviders,
  wsCatalogs,
  wsCFDi,
};
