<template>
  <aside
    :class="`fixed top-0 left-0 z-10 w-64 h-screen pt-14 transition-transform ${
      props.isVisible ? ` -translate-x-full ` : ``
    }bg-gray-50 dark:bg-gray-900 border-r border-gray-200 md:translate-x-0 dark:border-gray-700`"
    aria-label="Sidenav"
    id="drawer-navigation"
  >
    <div class="overflow-y-auto py-5 px-3 h-full bg-gray-50 dark:bg-gray-900">
      <ul class="space-y-2">
        <li>
          <router-link
            to="/home"
            class="flex items-center p-2 text-base font-medium text-gray-900 rounded-lg dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700 group"
            :class="{
              'dark:bg-gray-700': selected === '/home',
            }"
          >
            <svg
              class="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              width="24"
              height="24"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path>
              <path d="M22 12A10 10 0 0 0 12 2v10z"></path>
            </svg>
            <span class="ml-3">Inicio</span>
          </router-link>
        </li>
        <li>
          <button
            @click="openMenuEmitidos = !openMenuEmitidos"
            class="flex items-center p-2 w-full text-base font-medium rounded-lg transition duration-75 text-gray-700 dark:text-gray-300 hover:bg-gray-200 hover:text-black dark:hover:bg-gray-700 dark:hover:text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              class="flex-shrink-0 w-6 h-6"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path
                d="M21.2 15c.7-1.2 1-2.5.7-3.9-.6-2-2.4-3.5-4.4-3.5h-1.2c-.7-3-3.2-5.2-6.2-5.6-3-.3-5.9 1.3-7.3 4-1.2 2.5-1 6.5.5 8.8m8.7-1.6V21"
              />
              <path d="M16 16l-4-4-4 4" />
            </svg>
            <span class="flex-1 ml-3 text-left whitespace-nowrap"
              >Emitidos</span
            >
            <svg
              aria-hidden="true"
              class="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
          <ul v-show="openMenuEmitidos" x-transition class="py-2 space-y-2">
            <li>
              <router-link
                v-show="false"
                to="/emision/dashboard"
                class="flex items-center p-2 pl-11 w-full text-base font-medium rounded-lg transition duration-75 group text-gray-700 dark:text-gray-300 hover:bg-gray-200 hover:text-black dark:hover:bg-gray-700 dark:hover:text-white"
                :class="{
                  'dark:bg-gray-700': selected === '/emision/dashboard',
                }"
                >Dashboard
              </router-link>
            </li>
            <li>
              <router-link
                to="/emision/cfdi"
                class="flex items-center p-2 pl-11 w-full text-base font-medium rounded-lg transition duration-75 group text-gray-700 dark:text-gray-300 hover:bg-gray-200 hover:text-black dark:hover:bg-gray-700 dark:hover:text-white"
                :class="{
                  'dark:bg-gray-700': selected === '/emision/cfdi',
                }"
                >CFDIs
              </router-link>
            </li>
            <li>
              <router-link
                v-show="false"
                to="/emision/pagos"
                class="flex items-center p-2 pl-11 w-full text-base font-medium rounded-lg transition duration-75 group text-gray-700 dark:text-gray-300 hover:bg-gray-200 hover:text-black dark:hover:bg-gray-700 dark:hover:text-white"
                :class="{
                  'dark:bg-gray-700': selected === '/emision/pagos',
                }"
                >Pagos
              </router-link>
            </li>
          </ul>
        </li>
        <li>
          <button
            @click="openMenuRecibidos = !openMenuRecibidos"
            class="flex items-center p-2 w-full text-base font-medium rounded-lg transition duration-75 text-gray-700 dark:text-gray-300 hover:bg-gray-200 hover:text-black dark:hover:bg-gray-700 dark:hover:text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              class="flex-shrink-0 w-6 h-6"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path
                d="M21.2 15c.7-1.2 1-2.5.7-3.9-.6-2-2.4-3.5-4.4-3.5h-1.2c-.7-3-3.2-5.2-6.2-5.6-3-.3-5.9 1.3-7.3 4-1.2 2.5-1 6.5.5 8.8M12 19.8V12M16 17l-4 4-4-4"
              />
            </svg>
            <span class="flex-1 ml-3 text-left whitespace-nowrap"
              >Recibidos</span
            >
            <svg
              aria-hidden="true"
              class="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
          <ul v-show="openMenuRecibidos" x-transition class="py-2 space-y-2">
            <li>
              <router-link
                to="/recepcion/dashboard"
                v-show="false"
                class="flex items-center p-2 pl-11 w-full text-base font-medium rounded-lg transition duration-75 group text-gray-700 dark:text-gray-300 hover:bg-gray-200 hover:text-black dark:hover:bg-gray-700 dark:hover:text-white"
                :class="{
                  'dark:bg-gray-700': selected === '/recepcion/dashboard',
                }"
                >Dashboard
              </router-link>
            </li>
            <li>
              <router-link
                to="/recepcion/cfdi"
                class="flex items-center p-2 pl-11 w-full text-base font-medium rounded-lg transition duration-75 group text-gray-700 dark:text-gray-300 hover:bg-gray-200 hover:text-black dark:hover:bg-gray-700 dark:hover:text-white"
                :class="{
                  'dark:bg-gray-700': selected === '/recepcion/cfdi',
                }"
                >CFDIs
              </router-link>
            </li>
            <li>
              <router-link
                to="/recepcion/pagos"
                v-show="false"
                class="flex items-center p-2 pl-11 w-full text-base font-medium rounded-lg transition duration-75 group text-gray-700 dark:text-gray-300 hover:bg-gray-200 hover:text-black dark:hover:bg-gray-700 dark:hover:text-white"
                :class="{
                  'dark:bg-gray-700': selected === '/recepcion/pagos',
                }"
                >Pagos
              </router-link>
            </li>
          </ul>
        </li>
        <li>
          <router-link
            to="/clientes"
            class="flex items-center p-2 text-base font-medium text-gray-900 rounded-lg dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700 group"
            :class="{
              'dark:bg-gray-700': selected === '/clientes',
            }"
          >
            <svg
              class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  d="M4 21C4 17.4735 6.60771 14.5561 10 14.0709M16.4976 16.2119C15.7978 15.4328 14.6309 15.2232 13.7541 15.9367C12.8774 16.6501 12.7539 17.843 13.4425 18.6868C13.8312 19.1632 14.7548 19.9983 15.4854 20.6353C15.8319 20.9374 16.0051 21.0885 16.2147 21.1503C16.3934 21.203 16.6018 21.203 16.7805 21.1503C16.9901 21.0885 17.1633 20.9374 17.5098 20.6353C18.2404 19.9983 19.164 19.1632 19.5527 18.6868C20.2413 17.843 20.1329 16.6426 19.2411 15.9367C18.3492 15.2307 17.1974 15.4328 16.4976 16.2119ZM15 7C15 9.20914 13.2091 11 11 11C8.79086 11 7 9.20914 7 7C7 4.79086 8.79086 3 11 3C13.2091 3 15 4.79086 15 7Z"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </g>
            </svg>
            <span class="flex-1 ml-3 whitespace-nowrap">Clientes</span>
          </router-link>
        </li>
        <li>
          <router-link
            to="/proveedores"
            class="flex items-center p-2 w-full text-base font-medium text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-200 dark:text-white dark:hover:bg-gray-700"
            :class="{
              'dark:bg-gray-700': selected === '/proveedores',
            }"
          >
            <svg
              class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  d="M22 7.99995H20M20 7.99995H19C17 6.00173 14 3.99974 12 5.99995M20 7.99995V15.9999M12 5.99995L8.99956 9.00158C8.9202 9.08097 8.88052 9.12066 8.84859 9.1558C8.15499 9.91889 8.15528 11.0842 8.84927 11.847C8.88121 11.8821 8.92098 11.9218 9.00031 12.0011C9.07967 12.0804 9.11936 12.1201 9.15449 12.152C9.91743 12.8453 11.0824 12.8452 11.8451 12.1516C11.8802 12.1197 11.9199 12.08 11.9992 12.0007L12.9996 11.0003M12 5.99995C10 3.99974 7 6.0018 5 8.00001H4M2 8.00001H4M4 8.00001V15.9999M20 15.9999V18.9999H22M20 15.9999H17.1716M15 12.9999L16.5 14.4999C16.5796 14.5796 16.6195 14.6194 16.6515 14.6547C17.3449 15.4175 17.3449 16.5824 16.6515 17.3452C16.6195 17.3805 16.5796 17.4203 16.5 17.4999C16.4204 17.5795 16.3805 17.6194 16.3453 17.6515C15.5824 18.3449 14.4176 18.3449 13.6547 17.6515C13.6195 17.6194 13.5796 17.5795 13.5 17.4999L13 16.9999C12.4548 17.5452 12.1821 17.8178 11.888 17.9636C11.3285 18.2408 10.6715 18.2408 10.112 17.9636C9.81788 17.8178 9.54525 17.5452 9 16.9999C8.31085 17.9188 6.89563 17.7912 6.38197 16.7639L6 15.9999H4M4 15.9999V18.9999H2"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </g>
            </svg>
            <span class="flex-1 ml-3 text-left whitespace-nowrap"
              >Proveedores</span
            >
          </router-link>
        </li>
        <li>
          <router-link
            to="/productos"
            class="flex items-center p-2 w-full text-base font-medium text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-200 dark:text-white dark:hover:bg-gray-700"
            :class="{
              'dark:bg-gray-700': selected === '/productos',
            }"
          >
            <svg
              class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path
                d="M6 2L3 6v14c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2V6l-3-4H6zM3.8 6h16.4M16 10a4 4 0 1 1-8 0"
              />
            </svg>
            <span class="flex-1 ml-3 text-left whitespace-nowrap"
              >Productos</span
            >
          </router-link>
        </li>
      </ul>
      <ul
        class="pt-5 mt-5 space-y-2 border-t border-gray-200 dark:border-gray-700"
      >
        <li x-data="{ open: false }">
          <button
            @click="openMenuNominas = !openMenuNominas"
            class="flex items-center p-2 w-full text-base font-medium rounded-lg transition duration-75 text-gray-700 dark:text-gray-300 hover:bg-gray-200 hover:text-black dark:hover:bg-gray-700 dark:hover:text-white"
          >
            <svg
              class="w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  d="M17 13H21V19C21 20.1046 20.1046 21 19 21M17 13V19C17 20.1046 17.8954 21 19 21M17 13V5.75707C17 4.85168 17 4.39898 16.8098 4.13646C16.6439 3.90746 16.3888 3.75941 16.1076 3.72897C15.7853 3.69408 15.3923 3.91868 14.6062 4.36788L14.2938 4.54637C14.0045 4.7117 13.8598 4.79438 13.7062 4.82675C13.5702 4.85539 13.4298 4.85539 13.2938 4.82675C13.1402 4.79438 12.9955 4.7117 12.7062 4.54637L10.7938 3.45359C10.5045 3.28826 10.3598 3.20559 10.2062 3.17322C10.0702 3.14457 9.92978 3.14457 9.79383 3.17322C9.64019 3.20559 9.49552 3.28826 9.20618 3.4536L7.29382 4.54637C7.00448 4.71171 6.85981 4.79438 6.70617 4.82675C6.57022 4.85539 6.42978 4.85539 6.29383 4.82675C6.14019 4.79438 5.99552 4.71171 5.70618 4.54637L5.39382 4.36788C4.60772 3.91868 4.21467 3.69408 3.89237 3.72897C3.61123 3.75941 3.35611 3.90746 3.1902 4.13646C3 4.39898 3 4.85168 3 5.75707V16.2C3 17.8801 3 18.7202 3.32698 19.362C3.6146 19.9264 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21H19M12 10.5C11.5 10.376 10.6851 10.3714 10 10.376C9.77091 10.3775 9.90941 10.3678 9.6 10.376C8.79258 10.4012 8.00165 10.7368 8 11.6875C7.99825 12.7003 9 13 10 13C11 13 12 13.2312 12 14.3125C12 15.1251 11.1925 15.4812 10.1861 15.5991C9.3861 15.5991 9 15.625 8 15.5M10 16V17M10 8.99998V9.99998"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </g>
            </svg>
            <span class="flex-1 ml-3 text-left whitespace-nowrap">Nómina</span>
            <svg
              aria-hidden="true"
              class="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
          <ul v-show="openMenuNominas" x-transition class="py-2 space-y-2">
            <li>
              <router-link
                to="/nomina/cfdi"
                class="flex items-center p-2 pl-11 w-full text-base font-medium rounded-lg transition duration-75 group text-gray-700 dark:text-gray-300 hover:bg-gray-200 hover:text-black dark:hover:bg-gray-700 dark:hover:text-white"
                :class="{
                  'dark:bg-gray-700': selected === '/nomina/cfdi',
                }"
                >CFDIs
              </router-link>
            </li>
            <li>
              <router-link
                to="/nomina/empleados"
                class="flex items-center p-2 pl-11 w-full text-base font-medium rounded-lg transition duration-75 group text-gray-700 dark:text-gray-300 hover:bg-gray-200 hover:text-black dark:hover:bg-gray-700 dark:hover:text-white"
                :class="{
                  'dark:bg-gray-700': selected === '/nomina/empleados',
                }"
                >Empleados
              </router-link>
            </li>
          </ul>
        </li>
        <!-- <li>
          <router-link
            to="/fiscal"
            class="flex items-center p-2 text-base font-medium text-gray-900 rounded-lg transition duration-75 hover:bg-gray-200 dark:hover:bg-gray-700 dark:text-white group"
          >
            <svg
              class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  d="M3 21H21M3 18H21M6 18.0001V13.0001M10 18.0001V13.0001M14 18.0001V13.0001M18 18.0001V13.0001M21 9.99999L14.126 3.88974C13.3737 3.22108 12.9976 2.88675 12.5732 2.75979C12.1992 2.64794 11.8008 2.64794 11.4268 2.75979C11.0024 2.88675 10.6263 3.22108 9.87404 3.88974L3 9.99999H21Z"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </g>
            </svg>
            <span class="ml-3">Fiscal</span>
          </router-link>
        </li> -->
      </ul>
    </div>

    <!-- In Progress -->
    <div
      v-show="estatus.EstadoProceso === 1"
      class="hidden absolute bottom-0 left-0 justify-center p-4 space-x-4 w-full lg:flex bg-white dark:bg-gray-800 z-20"
    >
      <div
        class="w-5 h-5 rounded-full animate-spin border-2 border-solid border-green-500 border-t-transparent"
      ></div>
      <h1 class="text-sm font-semibold text-gray-700 dark:text-gray-200">
        Sincronizando
      </h1>
    </div>
    <!-- Iddle -->
    <div
      v-show="estatus.EstadoProceso === 0"
      class="hidden absolute bottom-0 left-0 justify-center p-4 space-x-4 w-full lg:flex bg-white dark:bg-gray-800 z-20"
    >
      <svg
        class="w-5 h-5 mx-1 text-green-400"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="M2.5 2v6h6M21.5 22v-6h-6" />
        <path d="M22 11.5A10 10 0 0 0 3.2 7.2M2 12.5a10 10 0 0 0 18.8 4.2" />
      </svg>

      <span class="group relative">
        <div
          class="absolute bottom-[calc(100%+0.5rem)] left-[50%] -translate-x-[50%] hidden group-hover:block w-auto"
        >
          <div
            class="bottom-full right-0 rounded bg-gray-600 px-4 py-4 text-sm text-white w-60 h-16rem max-w-2xl max-h-96"
          >
            Nuevos CFDis <strong>{{ estatus.NuevosCFDIs }}</strong>
            <svg
              class="absolute left-0 top-full h-2 w-full text-gray-600"
              x="0px"
              y="0px"
              viewBox="0 0 255 255"
              xml:space="preserve"
            >
              <polygon class="fill-current" points="0,0 127.5,127.5 255,0" />
            </svg>
          </div>
        </div>
        <span class="text-blue-500 dashed-underline group relative">
          <h1 class="text-sm font-semibold text-gray-700 dark:text-gray-200">
            {{ getStateDate }}
          </h1>
        </span>
      </span>
    </div>
    <!-- ERROR -->
    <div
      v-show="estatus.EstadoProceso === 2"
      class="hidden absolute bottom-0 left-0 justify-center p-4 space-x-4 w-full lg:flex bg-white dark:bg-gray-800 z-20"
    >
      <svg
        class="w-5 h-5 mx-1 text-red-400"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="M2.5 2v6h6M21.5 22v-6h-6" />
        <path d="M22 11.5A10 10 0 0 0 3.2 7.2M2 12.5a10 10 0 0 0 18.8 4.2" />
      </svg>
      <h1 class="text-sm font-semibold text-gray-700 dark:text-gray-200">
        Error en sincronización
      </h1>
    </div>
  </aside>
</template>
<script setup>
import commons from "@/components/Commons/commons";
import { useCompanyStore } from "@/components/stores/CompanyStore";
import { useUserStore } from "@/components/stores/UserStore";
import enums from "@/services/enums/enums";
import wsCatalogs from "@/services/modules/wsCatalogs";
import { computed, defineProps, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const companyStore = useCompanyStore();
const userStore = useUserStore();
const props = defineProps({
  isVisible: {
    type: Boolean,
    default: false,
  },
  id: {
    type: Number,
    default: 0,
  },
});

const estatus = ref({
  Error: null,
  EstadoProceso: {
    default: -100,
    type: Number,
  },
  NuevosCFDIs: {
    default: 0,
    type: Number,
  },
  UltimaFechaActualizacion: {
    type: String,
    default: "",
  },
});
const selected = ref("");
const openMenuEmitidos = ref(false);
const openMenuRecibidos = ref(false);
const openMenuNominas = ref(false);

onMounted(() => {
  selected.value = route.path;
  getStatusProcess();
});

watch(
  () => companyStore.companySeleted,
  (n, o) => {
    getStatusProcess();
  }
);

watch(
  () => route.path,
  (n, o) => {
    selected.value = route.path;
  }
);

const getStatusProcess = () => {
  wsCatalogs
    .SAT_Sidebar_EstadoDelProceso(
      userStore.devKey,
      enums.TypeDevice.Web,
      enums.AdmixProduct.AdmixManagement,
      companyStore.companySeleted.id,
      userStore.user.userId
    )
    .then((res) => {
      // console.log(res);
      if (res.result) {
        estatus.value = res.response;
      }
    });
};

const getStateDate = computed(() =>
  commons.getHumanizeFormat(estatus.value.UltimaFechaActualizacion)
);
</script>
