import axios from "axios";
import apiConfig from "../configuration/api-config";

axios.defaults.baseURL = `${apiConfig.apiBaseUrl}`;
axios.defaults.headers.post["Content-Type"] = "application/json";

const wsBaseUrl = `/Kernel.svc/json/`;

var wsResponse = {
  result: false,
  response: null,
};

async function Accounts_Devices_Get(devicekey, typeDevice, admixProduct) {
  const url = `${wsBaseUrl}Accounts_Devices_Get/`;

  //#region Validaciones
  if (!devicekey) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado una llave de dispositivo.",
          Solution: "Verifique la conección a la API de Admix.",
          Type: 1,
        },
      },
    };
  }

  if (!typeDevice) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de dispositivo.",
          Solution: "Agrega un tipo de dispositivo disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (typeDevice >= 0 && typeDevice <= 10) ||
      typeDevice == 100 ||
      typeDevice == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de dispositivo no esta permitido.",
          Solution: "Verifica la lista de dispositivos disponibles.",
          Type: 1,
        },
      },
    };
  }

  if (!admixProduct) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de aplicacion.",
          Solution: "Agrega un tipo de producto de Admix disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (admixProduct >= -1 && admixProduct <= 16) ||
      admixProduct == 100 ||
      admixProduct == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de aplicacion no esta permitida.",
          Solution: "Verifica la lista de productos de Admix disponibles.",
          Type: 1,
        },
      },
    };
  }
  //#endregion

  // Formar el Request con los datos de la peticion
  let request = {
    Request: {
      DeviceKey: devicekey,
      DeviceKey_External: null,
      AdmixProduct: admixProduct,
      TypeDevice: typeDevice,
    },
  };

  try {
    //Enviar Peticion
    var res = await axios.post(url, request);

    //Regresar Respuesta obtenida de la variable data
    return {
      result: res.data.Accounts_Devices_GetResult,
      response: res.data.Response,
    };
  } catch (error) {
    wsResponse.response = {
      Error: {
        Code: "WSC01",
        Message: error,
        Solution: "Verifique la conección a la API de Admix.",
        Type: 1,
      },
    };
    return wsResponse;
  }
}

async function Accounts_Get(devicekey, typeDevice, admixProduct) {
  const url = `${wsBaseUrl}Accounts_Get/`;

  //#region Validaciones
  if (!devicekey) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado una llave de dispositivo.",
          Solution: "Verifique la conección a la API de Admix.",
          Type: 1,
        },
      },
    };
  }

  if (!typeDevice) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de dispositivo.",
          Solution: "Agrega un tipo de dispositivo disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (typeDevice >= 0 && typeDevice <= 10) ||
      typeDevice == 100 ||
      typeDevice == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de dispositivo no esta permitido.",
          Solution: "Verifica la lista de dispositivos disponibles.",
          Type: 1,
        },
      },
    };
  }

  if (!admixProduct) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de aplicacion.",
          Solution: "Agrega un tipo de producto de Admix disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (admixProduct >= -1 && admixProduct <= 16) ||
      admixProduct == 100 ||
      admixProduct == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de aplicacion no esta permitida.",
          Solution: "Verifica la lista de productos de Admix disponibles.",
          Type: 1,
        },
      },
    };
  }
  //#endregion

  // Formar el Request con los datos de la peticion
  let request = {
    Request: {
      DeviceKey: devicekey,
      AdmixProduct: admixProduct,
      TypeDevice: typeDevice,
    },
  };

  try {
    //Enviar Peticion
    var res = await axios.post(url, request);

    //Regresar Respuesta obtenida de la variable data
    return {
      result: res.data.Accounts_GetResult,
      response: res.data.Response,
    };
  } catch (error) {
    wsResponse.response = {
      Error: {
        Code: "WSC01",
        Message: error,
        Solution: "Verifique la conección a la API de Admix.",
        Type: 1,
      },
    };
    return wsResponse;
  }
}

export default {
  Accounts_Devices_Get,
  Accounts_Get,
};
