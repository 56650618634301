import axios from "axios";
import apiConfig from "../configuration/api-config";

axios.defaults.baseURL = `${apiConfig.apiBaseUrl}`;
axios.defaults.headers.post["Content-Type"] = "application/json";

const wsBaseUrl = `/Management.svc/json/`;

var wsResponse = {
  result: false,
  response: null,
};

async function Account_Password_Recovery_App(username) {
  const url = `${wsBaseUrl}Account_Password_Recovery_App/`;

  //#region Validaciones
  if (!username) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el usuario.",
          Solution: "Verifique la conección a la API de Admix.",
          Type: 1,
        },
      },
    };
  }
  //#endregion

  // Formar el Request con los datos de la peticion
  let request = {
    Request: {
      UserName: username,
      IpAddress: apiConfig.wsIpKey,
      DeviceName: apiConfig.wsNameKey,
    },
  };

  try {
    //Enviar Peticion
    var res = await axios.post(url, request);

    //Regresar Respuesta obtenida de la variable data
    return {
      result: res.data.Account_Password_Recovery_AppResult,
      response: res.data.Response,
    };
  } catch (error) {
    wsResponse.response = {
      Error: {
        Code: "WSC01",
        Message: error,
        Solution: "Verifique la conección a la API de Admix.",
        Type: 1,
      },
    };
    return wsResponse;
  }
}

async function Account_Password_Recovery_Verify_App(verificationCode) {
  const url = `${wsBaseUrl}Account_Password_Recovery_Verify_App/`;

  //#region Validaciones
  if (!verificationCode) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message:
            "No se ha especificado el codigo de reestablecimiento de contraseña.",
          Solution: "Revisa tu cuenta de correo.",
          Type: 1,
        },
      },
    };
  }
  //#endregion

  // Formar el Request con los datos de la peticion
  let request = {
    Request: {
      VerificationCode: verificationCode,
      IpAddress: apiConfig.wsIpKey,
      DeviceName: apiConfig.wsNameKey,
    },
  };

  try {
    //Enviar Peticion
    var res = await axios.post(url, request);
    //Regresar Respuesta obtenida de la variable data
    return {
      result: res.data.Account_Password_Recovery_Verify_AppResult,
      response: res.data.Response,
    };
  } catch (error) {
    console.log(error);
    wsResponse.response = {
      Error: {
        Code: "WSC01",
        Message: error,
        Solution: "Verifique la conección a la API de Admix.",
        Type: 1,
      },
    };
    return wsResponse;
  }
}

async function Account_Password_Recovery_Update_App(
  verificationCode,
  newPassword
) {
  const url = `${wsBaseUrl}Account_Password_Recovery_Update_App/`;

  //#region Validaciones
  if (!verificationCode) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message:
            "No se ha especificado el codigo de reestablecimiento de contraseña.",
          Solution: "Revisa tu cuenta de correo.",
          Type: 1,
        },
      },
    };
  }

  if (!newPassword) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado la contraseña del usuario.",
          Solution: "Agrega la contraseña del usuario especificado.",
          Type: 1,
        },
      },
    };
  }
  //#endregion

  // Formar el Request con los datos de la peticion
  let request = {
    Request: {
      VerificationCode: verificationCode,
      NewPassword: newPassword,
      IpAddress: apiConfig.wsIpKey,
      DeviceName: apiConfig.wsNameKey,
    },
  };

  try {
    //Enviar Peticion
    var res = await axios.post(url, request);

    //Regresar Respuesta obtenida de la variable data
    return {
      result: res.data.Account_Password_Recovery_Update_AppResult,
      response: res.data.Response,
    };
  } catch (error) {
    wsResponse.response = {
      Error: {
        Code: "WSC01",
        Message: error,
        Solution: "Verifique la conección a la API de Admix.",
        Type: 1,
      },
    };
    return wsResponse;
  }
}

async function ExistAccount(username) {
  const url = `${wsBaseUrl}ExistAccount/`;

  //#region Validaciones
  if (!username) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el usuario.",
          Solution: "Verifique la conección a la API de Admix.",
          Type: 1,
        },
      },
    };
  }
  //#endregion

  // Formar el Request con los datos de la peticion
  let request = {
    Request: {
      UserName: username,
      IpAddress: apiConfig.wsIpKey,
      DeviceName: apiConfig.wsNameKey,
    },
  };

  try {
    //Enviar Peticion
    var res = await axios.post(url, request);

    //Regresar Respuesta obtenida de la variable data
    return {
      result: res.data.ExistAccountResult,
      response: res.data.Response,
    };
  } catch (error) {
    wsResponse.response = {
      Error: {
        Code: "WSC01",
        Message: error,
        Solution: "Verifique la conección a la API de Admix.",
        Type: 1,
      },
    };
    return wsResponse;
  }
}

async function RegisterNewAccount(username, password, idProduct, fullName) {
  const url = `${wsBaseUrl}RegisterNewAccount/`;

  //#region Validaciones
  if (!username) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el usuario.",
          Solution: "Agrega una cuenta de usuario.",
          Type: 1,
        },
      },
    };
  }

  if (!password) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado la contraseña del usuario.",
          Solution: "Agrega la contraseña del usuario especificado.",
          Type: 1,
        },
      },
    };
  }

  if (!idProduct) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado un bundle de Admix.",
          Solution: "Agrega un bundle de Admix disponible.",
          Type: 1,
        },
      },
    };
  }

  if (!(idProduct > 0)) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese un bundle de Admix no esta permitido.",
          Solution: "Verifica la lista de bundles de Admix.",
          Type: 1,
        },
      },
    };
  }

  if (!fullName) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el nombre completo del usuario.",
          Solution: "Agrega el nombre completo del usuario.",
          Type: 1,
        },
      },
    };
  }
  //#endregion

  // Formar el Request con los datos de la peticion
  let request = {
    Request: {
      UserName: username,
      Password: password,
      IdProduct: idProduct,
      FullName: fullName,
      IpAddress: apiConfig.wsIpKey,
      DeviceName: apiConfig.wsNameKey,
    },
  };

  try {
    //Enviar Peticion
    var res = await axios.post(url, request);

    //Regresar Respuesta obtenida de la variable data
    return {
      result: res.data.RegisterNewAccountResult,
      response: res.data.Response,
    };
  } catch (error) {
    wsResponse.response = {
      Error: {
        Code: "WSC01",
        Message: error,
        Solution: "Verifique la conección a la API de Admix.",
        Type: 1,
      },
    };
    return wsResponse;
  }
}

async function ReSendCode(username) {
  const url = `${wsBaseUrl}ReSendCode/`;

  //#region Validaciones
  if (!username) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el usuario.",
          Solution: "Agrega una cuenta de usuario.",
          Type: 1,
        },
      },
    };
  }
  //#endregion

  // Formar el Request con los datos de la peticion
  let request = {
    Request: {
      UserName: username,
      IpAddress: apiConfig.wsIpKey,
      DeviceName: apiConfig.wsNameKey,
    },
  };

  try {
    //Enviar Peticion
    var res = await axios.post(url, request);

    //Regresar Respuesta obtenida de la variable data
    return {
      result: res.data.ReSendCodeResult,
      response: res.data.Response,
    };
  } catch (error) {
    wsResponse.response = {
      Error: {
        Code: "WSC01",
        Message: error,
        Solution: "Verifique la conección a la API de Admix.",
        Type: 1,
      },
    };
    return wsResponse;
  }
}

async function VerifyRegisterNewAccount(verificationCode) {
  const url = `${wsBaseUrl}VerifyRegisterNewAccount/`;

  //#region Validaciones
  if (!verificationCode) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el codigo de verificación de cuenta.",
          Solution: "Revisa tu cuenta de correo.",
          Type: 1,
        },
      },
    };
  }
  //#endregion

  // Formar el Request con los datos de la peticion
  let request = {
    Request: {
      VerificationCode: verificationCode,
      IpAddress: apiConfig.wsIpKey,
      DeviceName: apiConfig.wsNameKey,
    },
  };

  try {
    //Enviar Peticion
    var res = await axios.post(url, request);

    //Regresar Respuesta obtenida de la variable data
    return {
      result: res.data.VerifyRegisterNewAccountResult,
      response: res.data.Response,
    };
  } catch (error) {
    wsResponse.response = {
      Error: {
        Code: "WSC01",
        Message: error,
        Solution: "Verifique la conección a la API de Admix.",
        Type: 1,
      },
    };
    return wsResponse;
  }
}

async function GetBundleProduct(username) {
  const url = `${wsBaseUrl}GetBundleProduct/`;

  //#region Validaciones
  if (!username) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el usuario.",
          Solution: "Agrega una cuenta de usuario.",
          Type: 1,
        },
      },
    };
  }
  //#endregion

  // Formar el Request con los datos de la peticion
  let request = {
    Request: {
      UserName: username,
      IpAddress: apiConfig.wsIpKey,
      DeviceName: apiConfig.wsNameKey,
    },
  };

  try {
    //Enviar Peticion
    var res = await axios.post(url, request);

    //Regresar Respuesta obtenida de la variable data
    return {
      result: res.data.GetBundleProductResult,
      response: res.data.Response,
    };
  } catch (error) {
    wsResponse.response = {
      Error: {
        Code: "WSC01",
        Message: error,
        Solution: "Verifique la conección a la API de Admix.",
        Type: 1,
      },
    };
    return wsResponse;
  }
}

/**
 * Falta el tipo de app para registrar ese bundle en la plataforma.
 */
async function GetListBundles() {
  const url = `${wsBaseUrl}List_Bundles_Get/`;
  // Formar el Request con los datos de la peticion
  let request = {
    Request: {
      AdmixProduct: -1, //AdmixProduct.AdmixManagement
      IpAddress: apiConfig.wsIpKey,
      DeviceName: apiConfig.wsNameKey,
      TypeDevice: 9, //TypeDevice.Web
      App: -999,
    },
  };

  try {
    //Enviar Peticion
    var res = await axios.post(url, request);

    //Regresar Respuesta obtenida de la variable data
    return {
      result: res.data.GetBundleProductResult,
      response: res.data.Response,
    };
  } catch (error) {
    wsResponse.response = {
      Error: {
        Code: "WSC01",
        Message: error,
        Solution: "Verifique la conección a la API de Admix.",
        Type: 1,
      },
    };
    return wsResponse;
  }
}

async function SendIdCliente(username, idCliente) {
  const url = `${wsBaseUrl}SendIdCliente/`;

  //#region Validaciones
  if (!username) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el usuario.",
          Solution: "Agrega una cuenta de usuario.",
          Type: 1,
        },
      },
    };
  }

  if (!idCliente) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el cliente Admix.",
          Solution: "Agrega el cliente de Admix asociado.",
          Type: 1,
        },
      },
    };
  }

  if (!(idCliente > 0)) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese cliente de Admix no esta registrado.",
          Solution: "Verifica si estas en la lista de clientes de Admix.",
          Type: 1,
        },
      },
    };
  }
  //#endregion

  // Formar el Request con los datos de la peticion
  let request = {
    Request: {
      UserName: username,
      id_Cliente: idCliente,
      IpAddress: apiConfig.wsIpKey,
      DeviceName: apiConfig.wsNameKey,
    },
  };

  try {
    //Enviar Peticion
    var res = await axios.post(url, request);

    //Regresar Respuesta obtenida de la variable data
    return {
      result: res.data.SendIdClienteResult,
      response: res.data.Response,
    };
  } catch (error) {
    wsResponse.response = {
      Error: {
        Code: "WSC01",
        Message: error,
        Solution: "Verifique la conección a la API de Admix.",
        Type: 1,
      },
    };
    return wsResponse;
  }
}

async function AccountPayPending(username) {
  const url = `${wsBaseUrl}AccountPayPending/`;

  //#region Validaciones
  if (!username) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el usuario.",
          Solution: "Agrega una cuenta de usuario.",
          Type: 1,
        },
      },
    };
  }
  //#endregion

  // Formar el Request con los datos de la peticion
  let request = {
    Request: {
      UserName: username,
      IpAddress: apiConfig.wsIpKey,
      DeviceName: apiConfig.wsNameKey,
    },
  };

  try {
    //Enviar Peticion
    var res = await axios.post(url, request);

    //Regresar Respuesta obtenida de la variable data
    return {
      result: res.data.AccountPayPendingResult,
      response: res.data.Response,
    };
  } catch (error) {
    wsResponse.response = {
      Error: {
        Code: "WSC01",
        Message: error,
        Solution: "Verifique la conección a la API de Admix.",
        Type: 1,
      },
    };
    return wsResponse;
  }
}

async function PayBundleProduct(username, isFree) {
  const url = `${wsBaseUrl}PayBundleProduct/`;

  //#region Validaciones
  if (!username) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el usuario.",
          Solution: "Agrega una cuenta de usuario.",
          Type: 1,
        },
      },
    };
  }
  //#endregion

  // Formar el Request con los datos de la peticion
  let request = {
    Request: {
      UserName: username,
      IsFree: isFree,
      IpAddress: apiConfig.wsIpKey,
      DeviceName: apiConfig.wsNameKey,
    },
  };

  try {
    //Enviar Peticion
    var res = await axios.post(url, request);

    //Regresar Respuesta obtenida de la variable data
    return {
      result: res.data.PayBundleProductResult,
      response: res.data.Response,
    };
  } catch (error) {
    wsResponse.response = {
      Error: {
        Code: "WSC01",
        Message: error,
        Solution: "Verifique la conección a la API de Admix.",
        Type: 1,
      },
    };
    return wsResponse;
  }
}

async function StartAccountProcess(username, isFree, idGuess, idPayment) {
  const url = `${wsBaseUrl}StartAccountProcess/`;

  //#region Validaciones
  if (!username) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el usuario.",
          Solution: "Agrega una cuenta de usuario.",
          Type: 1,
        },
      },
    };
  }

  // if(!idGuess){
  //     return {
  //         result: false,
  //         response: {
  //             Error: {
  //                 Code: 'WSC00',
  //                 Message: "No se ha especificado el Invitado.",
  //                 Solution: 'Agrega al Invitado.',
  //                 Type: 1,
  //             }
  //         }
  //     };
  // }

  // if(!idPayment){
  //     return {
  //         result: false,
  //         response: {
  //             Error: {
  //                 Code: 'WSC00',
  //                 Message: "No se ha especificado el Pago.",
  //                 Solution: 'Agrega al Pago.',
  //                 Type: 1,
  //             }
  //         }
  //     };
  // }
  //#endregion

  // Formar el Request con los datos de la peticion
  let request = {
    Request: {
      UserName: username,
      isFree: isFree,
      IdGuess: idGuess,
      IdPayment: idPayment,
      IpAddress: apiConfig.wsIpKey,
      DeviceName: apiConfig.wsNameKey,
    },
  };

  try {
    //Enviar Peticion
    var res = await axios.post(url, request);

    //Regresar Respuesta obtenida de la variable data
    return {
      result: res.data.StartAccountProcessResult,
      response: res.data.Response,
    };
  } catch (error) {
    wsResponse.response = {
      Error: {
        Code: "WSC01",
        Message: error,
        Solution: "Verifique la conección a la API de Admix.",
        Type: 1,
      },
    };
    return wsResponse;
  }
}

export default {
  Account_Password_Recovery_App,
  Account_Password_Recovery_Verify_App,
  Account_Password_Recovery_Update_App,

  ExistAccount,
  RegisterNewAccount,
  ReSendCode,
  VerifyRegisterNewAccount,
  GetBundleProduct,
  GetListBundles,
  SendIdCliente,
  AccountPayPending,
  PayBundleProduct,
  StartAccountProcess,
};
