<template>
  <div
    class="relative inline-flex items-center justify-center w-12 h-12 overflow-hidden text-green-400 bg-teal-50 dark:bg-gray-900 rounded-full"
  >
    <svg
      class="w-5 h-5 mx-1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path d="M21.5 12H16c-.7 2-2 3-4 3s-3.3-1-4-3H2.5" />
      <path
        d="M5.5 5.1L2 12v6c0 1.1.9 2 2 2h16a2 2 0 002-2v-6l-3.4-6.9A2 2 0 0016.8 4H7.2a2 2 0 00-1.8 1.1z"
      />
    </svg>
  </div>
</template>
