<template>
  <div
    id="selectCompanyBox"
    :class="open ? 'relative z-50' : 'relative z-50 hidden'"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="fixed inset-0 bg-gray-800 bg-opacity-50 transition-opacity"
    ></div>

    <div class="fixed inset-0 z-50 w-screen overflow-y-auto">
      <div class="flex min-h-full justify-center p-4 items-center sm:p-0">
        <div
          class="relative transform overflow-hidden rounded-lg bg-gray-100 dark:bg-gray-900 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl"
        >
          <div
            class="bg-slate-100 dark:bg-slate-900 px-4 pb-4 pt-5 sm:p-6 sm:pb-4"
          >
            <div class="sm:flex sm:items-start">
              <div class="sm:mt-0 text-left">
                <h3
                  class="text-2xl font-semibold leading-6 text-gray-900 dark:text-gray-200 mt-2"
                >
                  Seleccionar empresa
                </h3>
                <div class="my-4">
                  <p class="text-sm text-gray-500">
                    Utiliza el cuadro de búsqueda para encontrar la empresa que
                    deseas seleccionar.
                  </p>
                </div>
                <div class="mt-4">
                  <div class="flex rounded-lg shadow-sm">
                    <input
                      type="text"
                      v-model="filter"
                      name="inline-add-on"
                      class="py-2 px-3 block w-full border-2 border-gray-200 dark:border-gray-600 rounded-lg rounded-e-none text-base text-gray-700 dark:text-gray-300 focus:outline-none focus:border-blue-300 disabled:opacity-50 disabled:pointer-events-none dark:bg-gray-900 dark:focus:ring-gray-600 dark:placeholder-gray-600"
                      placeholder="Buscar empresa"
                    />
                    <button
                      type="button"
                      class="w-[2.875rem] h-[2.875rem] flex-shrink-0 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-e-md border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                    >
                      <svg
                        class="flex-shrink-0 size-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <circle cx="11" cy="11" r="8" />
                        <path d="m21 21-4.3-4.3" />
                      </svg>
                    </button>
                  </div>
                </div>

                <!-- Scrollable section -->
                <div
                  class="h-80 space-y-4 overflow-y-auto w-full rounded-lg bg-white dark:bg-gray-800 my-3"
                >
                  <a
                    v-for="company in filtered"
                    :key="company.id"
                    @click="onCompanySelectionChanged(company)"
                    class="flex items-center p-3 text-sm text-gray-600 transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    <div
                      class="relative inline-flex items-center justify-center w-12 h-12 overflow-hidden bg-gray-300 rounded-lg dark:bg-gray-600"
                    >
                      <span class="text-base text-gray-600 dark:text-gray-300">
                        {{ initials(company.RFC) }}
                      </span>
                    </div>
                    <div class="mx-1 ml-2">
                      <h1
                        class="text-sm font-semibold text-gray-700 dark:text-gray-200"
                      >
                        {{ company.RFC }}
                      </h1>
                      <p class="text-sm text-gray-500 dark:text-gray-400">
                        {{ company.Nombre }}
                      </p>
                    </div>
                  </a>
                </div>
                <div
                  class="bg-slate-100 dark:bg-slate-900 py-3 sm:flex sm:flex-row-reverse"
                >
                  <button
                    type="button"
                    @click="onClose"
                    class="inline-flex w-full justify-center rounded-md bg-blue-600 px-6 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-700 sm:ml-3 sm:w-auto"
                  >
                    Cerrar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import commons from "@/components/Commons/commons";
import { computed, ref, defineEmits, defineProps } from "vue";

const filter = ref("");

const props = defineProps({
  listCompanies: {
    type: Array,
    default: () => [],
  },
  open: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["onCompanySelectionChangeEvent"]);

const initials = (rfc) => commons.getFirstLetters(rfc);
const onCompanySelectionChanged = (company) =>
  emit("onCompanySelectionChangeEvent", company);
const onClose = () => emit("onCompanySelectionChangeEvent");

const filtered = computed(() => {
  return props.listCompanies.filter(
    (a) =>
      a.RFC.toString()
        .toLowerCase()
        .includes(filter.value.toLocaleLowerCase()) ||
      a.Nombre.toString()
        .toLowerCase()
        .includes(filter.value.toLocaleLowerCase())
  );
});
</script>
