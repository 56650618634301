import API from "@/services";
import enums from "@/services/enums/enums";
import { defineStore } from "pinia";
import { Key } from "./KeyString";

export const useCompanyStore = defineStore("Company", {
  state: () => ({
    companySeleted: JSON.parse(localStorage.getItem(Key.COMPANYSELECTED)),
    companies: JSON.parse(localStorage.getItem(Key.COMPANIES)) || [],
    query: "",
    loading: false,
  }),
  getters: {
    hasCompanies: (state) => state.companies.length > 0,
    getTop5Companies: (state) => state.companies.slice(0, 5),
    getCompaniesFiltered: (state) => (query) =>
      state.companies.filter((company) =>
        query
          ? company.Nombre.toLowerCase().includes(query.toLowerCase()) ||
            company.RFC.toLowerCase().includes(query.toLowerCase())
          : company
      ),
  },
  actions: {
    async getCompanies(devkey, userId) {
      if (!this.loading) {
        this.loading = true;
        const response = await API.wsCompany.HasCompany(
          devkey,
          enums.TypeDevice.Web,
          enums.AdmixProduct.AdmixManagement,
          userId
        );
        if (response.result) {
          ///saving companies
          this.companies = response.response.ListCompanies;
          localStorage.setItem(Key.COMPANIES, JSON.stringify(this.companies));

          ///set a default companySelected
          let selected = localStorage.getItem(Key.COMPANYSELECTED);

          if (selected) {
            const exists = this.companies.find((c) => c.id == selected.id);
            if (!exists) {
              selected = this.companies[0];
            }
          } else {
            selected = this.companies[0];
          }
          ///saving default companySelected
          this.companySeleted = selected;
          localStorage.setItem(
            Key.COMPANYSELECTED,
            JSON.stringify(this.companySeleted)
          );
        } else {
          this.companies = [];
          this.companySeleted = null;
        }
        this.loading = false;
      }
    },
    async getEmpresa_CSD_Info(devkey, csdB64, privateKeyB64, password) {
      return await API.wsCompany
        .Empresa_CSD_Info(
          devkey,
          enums.TypeDevice.Web, // web
          enums.AdmixProduct.AdmixManagement,
          csdB64,
          privateKeyB64,
          password
        )
        .then((res) => {
          if (res.response) {
            console.log(res);
            return res.response;
          }
          return null;
        });
    },
    async getEmpresa_Insert(devkey, company, userId) {
      return await API.wsCompany
        .Empresa_Insert(
          devkey,
          enums.TypeDevice.Web, // web
          enums.AdmixProduct.AdmixManagement,
          company.csdB64,
          company.privateKeyB64,
          company.password,
          company.companyName,
          company.rfc,
          company.tradeName,
          company.website,
          company.phone,
          userId
        )
        .then((res) => {
          console.log(res);
          if (res.result) {
            this.companies.push(res.response.Empresa);
            localStorage.setItem(Key.COMPANIES, JSON.stringify(this.companies));
          }
          return res;
        });
    },
    decodeBase64ToXML(base64) {
      try {
        // Decode the Base64 string
        const decodedString = atob(base64);

        // Parse it into an XML Document
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(decodedString, "application/xml");

        // Convert XML to string format
        const xmlSerializer = new XMLSerializer();
        const xmlString = xmlSerializer.serializeToString(xmlDoc);
        console.log(xmlString);
        const json = this.convertXmlToJson(xmlString);
        console.log(json);
      } catch (error) {
        console.error("Error parsing Base64 to XML:", error);
      }
    },
    convertXmlToJson(xmlString) {
      try {
        // Parse XML string into an XML Document
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlString, "application/xml");

        // Convert XML to JSON
        return this.xmlToJson(xmlDoc.documentElement);
      } catch (error) {
        console.error("Error parsing XML to JSON:", error);
        return {};
      }
    },
    xmlToJson(node) {
      let obj = {};

      // If the node has attributes, add them to the object
      if (node.attributes && node.attributes.length > 0) {
        obj["@attributes"] = {};
        for (let attr of node.attributes) {
          obj["@attributes"][attr.name] = attr.value;
        }
      }

      // If the node has child nodes, process them recursively
      if (node.childNodes.length > 0) {
        for (let child of node.childNodes) {
          if (child.nodeType === 3) {
            // Text node
            const text = child.nodeValue.trim();
            if (text) {
              obj["#text"] = text;
            }
          } else {
            const childName = child.nodeName;
            const childObj = this.xmlToJson(child);

            if (obj[childName]) {
              if (!Array.isArray(obj[childName])) {
                obj[childName] = [obj[childName]];
              }
              obj[childName].push(childObj);
            } else {
              obj[childName] = childObj;
            }
          }
        }
      }

      return obj;
    },
  },
});
