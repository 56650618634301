<!-- eslint-disable no-unused-vars -->
<template>
  <div class="bg-white dark:bg-gray-900">
    <div class="flex justify-center h-screen">
      <div
        class="hidden bg-cover lg:block lg:w-2/3 lg:w"
        style="
          background-image: url(https://images.unsplash.com/photo-1616763355603-9755a640a287?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80);
        "
      >
        <div class="flex items-center h-full px-20 bg-gray-900 bg-opacity-40">
          <div>
            <h2 class="text-2xl font-bold text-white sm:text-3xl">
              Comienza a Facturar <br />
              rápido, sencillo y en cualquier lugar
            </h2>

            <p class="max-w-xl mt-3 text-gray-300">
              Emite Comprobantes Fiscales Digitales por Internet (CFDi) con el
              software mas avanzado. No importa donde trabajes, siempre tendrás
              tu información disponible en tu computadora o equipo móvil.
            </p>
          </div>
        </div>
      </div>

      <div class="flex items-center w-full max-w-md px-6 mx-auto lg:w-2/6">
        <div class="flex-1">
          <div class="text-center">
            <div class="flex justify-center mx-auto">
              <!-- Light Mode Logo -->
              <img
                src="@/assets/za_logo.png"
                alt="Light Mode Logo"
                class="dark:hidden"
              />

              <!-- Dark Mode Logo -->
              <img
                src="@/assets/za_logo_white.png"
                alt="Dark Mode Logo"
                class="hidden dark:block"
              />
            </div>
            <h2
              class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-gray-300"
            >
              Inicia sesión en tu cuenta
            </h2>
          </div>

          <!-- CONDITIONAL FIELD -->
          <div
            id="error-message"
            v-if="hasError"
            class="bg-red-100 border border-red-400 text-red-700 px-5 py-3 rounded relative mb-4 mt-8 opacity-100 transition-opacity duration-500 ease-in-out"
          >
            <strong class="font-bold">Error: </strong>
            <span class="block sm:inline">{{ error }}</span>
            <button
              class="absolute top-0 right-0 px-3 py-4"
              @click="dismissErrorMessage"
            >
              <svg
                class="h-4 w-4 fill-current text-red-700"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path
                  d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                />
              </svg>
            </button>
          </div>

          <div class="mt-8">
            <form @submit.prevent>
              <div class="mt-6">
                <label
                  for="email"
                  class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200"
                  >Correo electrónico</label
                >
                <div class="relative flex items-center">
                  <span class="absolute">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-6 h-6 mx-3 mt-2 text-gray-300 dark:text-gray-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                      />
                    </svg>
                  </span>

                  <input
                    type="text"
                    v-focus
                    v-model="user"
                    class="block w-full py-3 text-gray-700 bg-white border rounded-lg px-11 dark:bg-gray-900 dark:text-gray-300 mt-2 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40 dark:placeholder-gray-600"
                    placeholder="correo@ejemplo.com"
                  />
                </div>
              </div>

              <div class="mt-6">
                <div class="flex justify-between mb-2">
                  <label
                    for="email"
                    class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200"
                    >Contraseña</label
                  >
                  <router-link
                    to="/forgot"
                    class="text-sm text-blue-500 hover:text-blue-300"
                  >
                    ¿Olvidaste tu contraseña?
                  </router-link>
                </div>

                <div class="relative flex items-center mt-2">
                  <span class="absolute">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-6 h-6 mx-3 text-gray-300 dark:text-gray-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                      />
                    </svg>
                  </span>

                  <input
                    type="password"
                    v-model="password"
                    class="block w-full px-10 py-3 text-gray-700 bg-white border rounded-lg dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40 dark:placeholder-gray-600"
                    placeholder="Contraseña"
                  />
                </div>
              </div>

              <div class="mt-6">
                <button
                  @click="onLogin"
                  class="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-blue-500 rounded-lg hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50"
                >
                  Iniciar sesión
                </button>
              </div>
            </form>

            <p class="mt-6 text-sm text-center text-gray-400">
              ¿Aún no tienes una cuenta?
              <router-link
                to="/signup"
                class="text-blue-500 hover:text-blue-300"
              >
                Registrate, es gratis!
              </router-link>
              .
            </p>
            <footer-component />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import FooterComponent from "./Footer/FooterComponent.vue";
import { useUserStore } from "../stores/UserStore";
import { ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useCompanyStore } from "../stores/CompanyStore";
import { useLoading } from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";

const userStore = useUserStore();
const companyStore = useCompanyStore();
const router = useRouter();

const $progress = useLoading({
  // options
});

const user = ref("");
const password = ref("");
const hasError = ref(false);
const error = ref(
  "El correo electrónico proporcionado ya existe, si es tu cuenta, intenta recuperar la contraseña."
);

const onLogin = async () => {
  const loader = $progress.show({
    color: "#FFFFFF",
  });
  const response = await userStore.login(user.value, password.value);
  if (response) {
    if (userStore.isAuthenticated) {
      await companyStore.getCompanies(userStore.devKey, userStore.user.userId);
      if (companyStore.hasCompanies) {
        loader.hide();
        router.push("home");
        return;
      }
      router.push("onboarding");
    } else {
      if (userStore.error) {
        error.value = userStore.error;
        hasError.value = true;
      }
    }
  } else {
    if (userStore.error) {
      error.value = userStore.error;
      hasError.value = true;
    }
  }
  loader.hide();
};

const dismissErrorMessage = () => {
  setTimeout(() => {
    hasError.value = false;
  }, 400);
};

watch(
  () => hasError,
  (n, o) => {
    if (n) {
      setTimeout(() => {
        dismissErrorMessage();
      }, 6000);
    }
  }
);
</script>
