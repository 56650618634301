<template>
  <a
    href="#"
    class="flex p-3 text-sm text-gray-600 transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
  >
    <div class="flex">
      <no-sync-icon v-show="notification.typeN == 0" />
      <sync-icon v-show="notification.typeN == 1" />
      <csf-icon v-show="notification.typeN == 2" />
      <csd-i-due-icon v-show="notification.typeN == 3" />
      <mail-box-icon v-show="notification.typeN == 4" />
    </div>
    <div class="flex flex-col ml-3">
      <h1 class="text-sm font-semibold text-gray-700 dark:text-gray-200">
        {{ titleText(notification.typeN) }}
        <!-- Error en Sincronización -->
      </h1>
      <p class="text-sm text-gray-500 dark:text-gray-400">
        <!-- La descarga programada de XMLs no se pudo realizar. -->
        {{ notification.description }}
      </p>
      <p class="text-sm font-extralight text-gray-500 dark:text-gray-400 mt-1">
        {{ getCurrentDateTime(notification.date) }}
        <!-- Hace 5 horas -->
      </p>
    </div>
    <!-- New Notification -->
    <div v-show="notification.isNew" class="flex items-center ml-2">
      <div class="w-2 h-2 bg-blue-500 rounded-full"></div>
    </div>
  </a>
</template>
<script setup>
import { defineProps, toRefs } from "vue";
import Dayjs from "dayjs";
import NoSyncIcon from "./NotificationIcons/NoSyncIcon.vue";
import CsdIDueIcon from "./NotificationIcons/CsdIDueIcon.vue";
import MailBoxIcon from "./NotificationIcons/MailBoxIcon.vue";
import SyncIcon from "./NotificationIcons/SyncIcon.vue";
import CsfIcon from "./NotificationIcons/CsfIcon.vue";

const props = defineProps({
  notification: {
    typeN: 0,
    id: 0,
    description: {
      type: String,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    date: String,
  },
});

const { notification } = toRefs(props);

const titleText = (type) => {
  let text = "";
  switch (type) {
    case 0:
      text = "Error en Sincronización";
      break;
    case 1:
      text = "Sincronización exitosa";
      break;
    case 2:
      text = "Descarga exitosa";
      break;
    case 3:
      text = "Certificado por vencer";
      break;
    case 4:
      text = "Sincronización exitosa";
      break;
    case 5:
      text = "Certificado vencido";
      break;
    default:
      text = "Tipo de notificacion no encontrado.";
      break;
  }
  return text;
};

const getCurrentDateTime = (date) => {
  // console.log(date);
  if (date) {
    const d = date.replace("/Date(", "").replace(")/", "");
    if (d) {
      const lastAccess = Dayjs(date);
      const currentDate = Dayjs();
      if (lastAccess.isToday()) {
        return "Hoy";
      } else if (lastAccess.isYesterday()) {
        return "Ayer";
      } else {
        const differenceInDays = lastAccess.diff(currentDate, "day");
        if (Math.abs(differenceInDays) < 30) {
          return Dayjs.duration(differenceInDays, "d").humanize(true);
        }
      }
      return lastAccess.format("DD [de] MMM, YYYY");
    }
  }
  // const clearDate = date.replace("/Date(", "");
  return date;
};
</script>
