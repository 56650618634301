<template>
  <div
    class="fixed flex flex-col p-5 top-0 left-0 items-center h-screen w-screen sidebar bg-white dark:bg-gray-900 dark:text-gray-300"
  >
    <companies-menu @onCompanySelectionChanged="$emit('close')" />
  </div>
</template>

<script>
import CompaniesMenu from "./CompaniesMenu.vue";
// import FooterTemplate from "./FooterTemplate.vue";
export default {
  components: { CompaniesMenu },
  name: "CompaniesMainMenu",
};
</script>

<style scoped></style>
