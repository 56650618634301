<template>
  <footer class="flex text-sm pb-2 px-4 lg:ml-64">
    <p class="text-gray-500">
      © 2024 Datex Tecnologías. Todos los derechos reservados.
    </p>
    <div class="flex-grow"></div>
    <a href="terms.html" class="text-blue-500 hover:text-blue-300 mx-2"
      >Términos y Condiciones</a
    >
    <a href="privacy.html" class="text-blue-500 hover:text-blue-300 mx-2"
      >Aviso de Privacidad</a
    >
  </footer>
</template>
