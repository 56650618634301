import axios from "axios";
import apiConfig from "../configuration/api-config";

axios.defaults.baseURL = `${apiConfig.apiBaseUrl}`;
axios.defaults.headers.post["Content-Type"] = "application/json";

const wsBaseUrl = `/Company.svc/json/`;

var wsResponse = {
  result: false,
  response: null,
};

async function Lista_Empresas_Get(
  devicekey,
  typeDevice,
  admixProduct,
  idUser,
  id = 0,
  idEmpresa = 0,
  lastItemValue = null,
  numItems = 0
) {
  const url = `${wsBaseUrl}Lista_Empresas_Get/`;

  //#region Validaciones
  if (!devicekey) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado una llave de dispositivo.",
          Solution: "Verifique la conección a la API de Admix.",
          Type: 1,
        },
      },
    };
  }

  if (!typeDevice) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de dispositivo.",
          Solution: "Agrega un tipo de dispositivo disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (typeDevice >= 0 && typeDevice <= 10) ||
      typeDevice == 100 ||
      typeDevice == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de dispositivo no esta permitido.",
          Solution: "Verifica la lista de dispositivos disponibles.",
          Type: 1,
        },
      },
    };
  }

  if (!admixProduct) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de aplicacion.",
          Solution: "Agrega un tipo de producto de Admix disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (admixProduct >= -1 && admixProduct <= 16) ||
      admixProduct == 100 ||
      admixProduct == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de aplicacion no esta permitida.",
          Solution: "Verifica la lista de productos de Admix disponibles.",
          Type: 1,
        },
      },
    };
  }
  //#endregion

  // Formar el Request con los datos de la peticion
  let request = {
    Request: {
      DeviceKey: devicekey,
      DeviceKey_External: null,
      AdmixProduct: admixProduct,
      TypeDevice: typeDevice,

      Id: id,
      IdEmpresa: idEmpresa,
      IdUser: idUser,
      LastItemValue: lastItemValue,
      NumItems: numItems,
    },
  };

  try {
    //Enviar Peticion
    var res = await axios(url, request);

    //Regresar Respuesta obtenida de la variable data
    return {
      result: res.data.Lista_Empresas_GetResult,
      response: res.data.Response,
    };
  } catch (error) {
    wsResponse.response = {
      Error: {
        Code: "WSC01",
        Message: error,
        Solution: "Verifique la conección a la API de Admix.",
        Type: 1,
      },
    };
    return wsResponse;
  }
}

async function Empresa_CSD_Info(
  devicekey,
  typeDevice,
  admixProduct,
  certificadoDigital,
  llavePrivada,
  llavePrivadaPassword
) {
  const url = `${wsBaseUrl}Empresa_CSD_Info/`;

  //#region Validaciones
  if (!devicekey) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado una llave de dispositivo.",
          Solution: "Verifique la conección a la API de Admix.",
          Type: 1,
        },
      },
    };
  }

  if (!typeDevice) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de dispositivo.",
          Solution: "Agrega un tipo de dispositivo disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (typeDevice >= 0 && typeDevice <= 10) ||
      typeDevice == 100 ||
      typeDevice == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de dispositivo no esta permitido.",
          Solution: "Verifica la lista de dispositivos disponibles.",
          Type: 1,
        },
      },
    };
  }

  if (!admixProduct) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de aplicacion.",
          Solution: "Agrega un tipo de producto de Admix disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (admixProduct >= -1 && admixProduct <= 16) ||
      admixProduct == 100 ||
      admixProduct == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de aplicacion no esta permitida.",
          Solution: "Verifica la lista de productos de Admix disponibles.",
          Type: 1,
        },
      },
    };
  }
  //#endregion

  // Formar el Request con los datos de la peticion
  let request = {
    Request: {
      DeviceKey: devicekey,
      AdmixProduct: admixProduct,
      TypeDevice: typeDevice,
      IdUser: -1,
      Object: {
        CertificadoDigital: certificadoDigital,
        LlavePrivada: llavePrivada,
        LlavePrivadaPassword: llavePrivadaPassword,
      },
    },
  };

  try {
    //Enviar Peticion
    console.log(request);
    var res = await axios.post(url, request);
    //Regresar Respuesta obtenida de la variable data
    return {
      result: res.data.Empresa_CSD_InfoResult,
      response: res.data.Response,
    };
  } catch (error) {
    wsResponse.response = {
      Error: {
        Code: "WSC01",
        Message: error,
        Solution: "Verifique la conección a la API de Admix.",
        Type: 1,
      },
    };
    return wsResponse;
  }
}

async function Empresa_Insert(
  devicekey,
  typeDevice,
  admixProduct,
  certificadoDigital,
  llavePrivada,
  llavePrivadaPassword,
  name,
  rfc,
  comertialName,
  website,
  telephones,
  userId
) {
  const url = `${wsBaseUrl}Empresa_Insert/`;

  //#region Validaciones
  if (!devicekey) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado una llave de dispositivo.",
          Solution: "Verifique la conección a la API de Admix.",
          Type: 1,
        },
      },
    };
  }

  if (!typeDevice) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de dispositivo.",
          Solution: "Agrega un tipo de dispositivo disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (typeDevice >= 0 && typeDevice <= 10) ||
      typeDevice == 100 ||
      typeDevice == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de dispositivo no esta permitido.",
          Solution: "Verifica la lista de dispositivos disponibles.",
          Type: 1,
        },
      },
    };
  }

  if (!admixProduct) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de aplicacion.",
          Solution: "Agrega un tipo de producto de Admix disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (admixProduct >= -1 && admixProduct <= 16) ||
      admixProduct == 100 ||
      admixProduct == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de aplicacion no esta permitida.",
          Solution: "Verifica la lista de productos de Admix disponibles.",
          Type: 1,
        },
      },
    };
  }
  //#endregion

  // Formar el Request con los datos de la peticion
  let request = {
    Request: {
      DeviceKey: devicekey,
      AdmixProduct: admixProduct,
      TypeDevice: typeDevice,
      Object: {
        CSD: {
          Archivos_CSD: {
            CertificadoDigital: certificadoDigital,
            LlavePrivada: llavePrivada,
            LlavePrivadaPassword: llavePrivadaPassword,
          },
        },
        Informacion_General: {
          Nombre: name,
          RFC: rfc,
          Nombre_Comercial: comertialName,
          Sitio_Web: website,
          Telefonos: telephones,
        },
        IdUser: userId,
      },
    },
  };
  console.log(request);
  try {
    //Enviar Peticion
    var res = await axios.post(url, request);
    //Regresar Respuesta obtenida de la variable data
    return {
      result: res.data.Empresa_InsertResult,
      response: res.data.Response,
    };
  } catch (error) {
    wsResponse.response = {
      Error: {
        Code: "WSC01",
        Message: error,
        Solution: "Verifique la conección a la API de Admix.",
        Type: 1,
      },
    };
    return wsResponse;
  }
}

async function HasCompany(devicekey, typeDevice, admixProduct, idUser) {
  // ${wsBaseUrl}
  const url = `${wsBaseUrl}Lista_Empresas_Get/`;

  //#region Validaciones
  if (!devicekey) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado una llave de dispositivo.",
          Solution: "Verifique la conección a la API de Admix.",
          Type: 1,
        },
      },
    };
  }

  if (!typeDevice) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de dispositivo.",
          Solution: "Agrega un tipo de dispositivo disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (typeDevice >= 0 && typeDevice <= 10) ||
      typeDevice == 100 ||
      typeDevice == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de dispositivo no esta permitido.",
          Solution: "Verifica la lista de dispositivos disponibles.",
          Type: 1,
        },
      },
    };
  }

  if (!admixProduct) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "No se ha especificado el tipo de aplicacion.",
          Solution: "Agrega un tipo de producto de Admix disponible.",
          Type: 1,
        },
      },
    };
  }

  if (
    !(
      (admixProduct >= -1 && admixProduct <= 16) ||
      admixProduct == 100 ||
      admixProduct == -100
    )
  ) {
    return {
      result: false,
      response: {
        Error: {
          Code: "WSC00",
          Message: "Ese tipo de aplicacion no esta permitida.",
          Solution: "Verifica la lista de productos de Admix disponibles.",
          Type: 1,
        },
      },
    };
  }
  //#endregion

  // Formar el Request con los datos de la peticion
  let request = {
    Request: {
      DeviceKey: devicekey,
      AdmixProduct: admixProduct,
      TypeDevice: typeDevice,
      Id: 0,
      IdEmpresa: 0,
      IdUser: idUser,
      NumItems: 1,
    },
  };

  try {
    //Enviar Peticion
    var res = await axios.post(url, request);

    //Regresar Respuesta obtenida de la variable data
    return {
      result: res.data.Lista_Empresas_GetResult,
      response: res.data.Response,
    };
  } catch (error) {
    wsResponse.response = {
      Error: {
        Code: "WSC01",
        Message: error,
        Solution: "Verifique la conección a la API de Admix.",
        Type: 1,
      },
    };
    return wsResponse;
  }
}

export default {
  Lista_Empresas_Get,
  Empresa_CSD_Info,
  Empresa_Insert,
  HasCompany,
};
