<template>
  <transition
    name="slide-fade"
    @after-leave="showSidebar = false"
    class="z-[999]"
  >
    <CompaniesMainMenu
      v-if="showSidebar"
      @close="toggleSidebar"
      @viewEmployees="viewEmployees"
    />
  </transition>
  <div
    :class="{
      'h-[700px] overflow-hidden': showSidebar === true,
    }"
    class="bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-200 font-sans antialiased"
  >
    <header-template
      @onCompanySelectionChangeEvent="onCompanySelectionChanged"
      @onClickShowAllCompaniesEvent="onClickShowAll"
      @onClickMenuButtonEvent="onClickMenuButton"
      @onPeriodSelectionChangeEvent="onPeriodSelectionChanged"
      :selectedCompanyId="companySelected.id"
    />

    <!-- Sidebar -->
    <side-bar :isVisible="isVisible" :id="companySelected.id" />

    <!-- MAIN CONTAINER -->
    <slot />

    <footer-template />
    <!-- Show full company list dialog box -->
    <companies-modal
      :open="openCompanies"
      :listCompanies="listCompanies"
      @onCompanySelectionChangeEvent="onCompanySelectionChanged"
    />
  </div>
</template>
<script setup>
import FooterTemplate from "@/components/Layout/FooterTemplate.vue";
import HeaderTemplate from "@/components/Layout/HeaderTemplate.vue";
import SideBar from "@/components/Layout/SideBar.vue";
import CompaniesModal from "@/components/Layout/CompaniesModal.vue";
import "@vuepic/vue-datepicker/dist/main.css";
import { ref, watch, onMounted } from "vue";
import { useUserStore } from "@/components/stores/UserStore";
import CompaniesMainMenu from "@/components/Layout/CompaniesMainMenu.vue";

const userStore = useUserStore();

const showSidebar = ref(true);
const openCompanies = ref(false);
const listCompanies = ref([]);

const companySelected = ref({
  RFC: "",
  Nombre: "",
  id: 0,
});

const isVisible = ref(false);
const fechaInicial = ref("");
const fechaFinal = ref("");

watch(
  () => companySelected.value,
  (n, o) => {}
);

onMounted(async () => {
  const period = userStore.getCurrentPeriod;
  fechaInicial.value = period.startDate;
  fechaFinal.value = period.endDate;
});

const onClickMenuButton = () => (isVisible.value = !isVisible.value);

const onPeriodSelectionChanged = (params) => {
  // const params = commons.getFirstAndLastDayInAMonth(date);
  fechaInicial.value = params.startDate;
  fechaFinal.value = params.endDate;
  //   getDocuments(true);
};

const onClickShowAll = () => {
  showSidebar.value = !showSidebar.value;
};

const onCompanySelectionChanged = (company) => {
  if (company != null) {
    companySelected.value = company;
    localStorage.setItem("companySelected", JSON.stringify(company));
    // $refs.ingresosWidget.update(id);
  }
  open.value = false;
  openCompanies.value = false;
};

const toggleSidebar = () => {
  showSidebar.value = !showSidebar.value;
};

const viewEmployees = () => {
  showSidebar.value = false;
};
</script>
<style scoped>
/* Estilos de transición para el sidebar */
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: transform 0.4s ease, opacity 0.4s ease;
}

.slide-fade-enter-from {
  transform: translateX(-100%);
  opacity: 0;
}

.slide-fade-enter-to {
  transform: translateX(0);
  opacity: 1;
}

.slide-fade-leave-from {
  transform: translateX(0);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}
</style>
