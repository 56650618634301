import Dayjs from "dayjs";

function getHumanizeFormat(date) {
  if (date) {
    const lastAccess = Dayjs(date);
    const currentDate = Dayjs();
    if (lastAccess.isToday()) {
      return "Hoy";
    } else if (lastAccess.isYesterday()) {
      return "Ayer";
    } else {
      const differenceInDays = lastAccess.diff(currentDate, "day");
      if (Math.abs(differenceInDays) < 30) {
        return Dayjs.duration(differenceInDays, "d").humanize(true);
      }
    }
    return lastAccess.format("DD [de] MMM, YYYY");
  }
  return date;
}

function getFormatedDate(date) {
  if (date) {
    const dateValue = Dayjs(date);
    return dateValue.format("DD/MM/YYYY");
  }
  return date;
}

function getFormatedDateStandardized(date) {
  if (date) {
    const dateValue = Dayjs(date);
    return dateValue.format("YYYY-MM-DDTHH:mm:ss");
  }
  return date;
}

function getInitialLetters(text, useFirstTwoCharacters) {
  if (!text) {
    return "";
  }
  const names = text.split(" ");
  let initials = "";
  switch (names[0].length) {
    case 1:
      initials = names[0].toUpperCase();
      break;
    case 2:
      initials = names[0].slice(0, 2).toUpperCase();
      break;
    default:
      initials = names[0].slice(0, useFirstTwoCharacters ? 2 : 3).toUpperCase();
      break;
  }
  return initials;
}

function getFirstAndLastDayInAMonth(date) {
  const firstDay = Dayjs(new Date(date.getFullYear(), date.getMonth(), 1));
  const firstDayFormatted = firstDay.format("YYYY-MM-DDTHH:mm:ss");
  const lastDay = Dayjs(
    new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59)
  );
  const lastDayFormatted = lastDay.format("YYYY-MM-DDTHH:mm:ss");
  return {
    firstDay: firstDayFormatted,
    lastDay: lastDayFormatted,
  };
}

function getFirstAndLastDayInAMonthFormattedEsMx(date) {
  const firstDay = Dayjs(new Date(date.getFullYear(), date.getMonth(), 1));
  const firstDayFormatted = firstDay.format("DD/MM/YYYY");
  const lastDay = Dayjs(
    new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59)
  );
  const lastDayFormatted = lastDay.format("DD/MM/YYYY");
  return {
    firstDay: firstDayFormatted,
    lastDay: lastDayFormatted,
  };
}

function getFirstLetters(text) {
  if (text) {
    return text.length >= 3 ? text.substring(0, 3) : text;
  }
  return text;
}

function isValidEmail(email) {
  let res = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
  return res.test(String(email).toLowerCase());
}

export default {
  getHumanizeFormat,
  getInitialLetters,
  getFormatedDate,
  getFirstAndLastDayInAMonth,
  getFirstAndLastDayInAMonthFormattedEsMx,
  getFormatedDateStandardized,
  getFirstLetters,
  isValidEmail,
};
