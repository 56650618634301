<template>
  <div class="flex flex-wrap justify-start">
    <header class="bg-gray-50 dark:bg-gray-900 shadow w-screen">
      <div class="mx-auto px-4 py-4 sm:px-6 lg:px-8 max-w-7xl">
        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <img
                class="h-8 w-8"
                src="@/assets/za_logo_icon.png"
                alt="Admix"
              />
            </div>
          </div>

          <div class="ml-4 flex items-center md:ml-6">
            <!-- Profile dropdown -->
            <!-- Profile dropdown -->
            <div
              class="relative ml-2"
              v-click-outside="onClickOutSideUserProfileDropdown"
            >
              <button
                @click="openMenu = !openMenu"
                type="button"
                class="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-1 focus:ring-white focus:ring-offset-1 focus:ring-offset-gray-800"
                id="user-menu-button"
                aria-expanded="false"
                aria-haspopup="true"
              >
                <span class="absolute -inset-1.5"></span>
                <span class="sr-only">Open user menu</span>
                <!-- IF !(AVATAR) -->
                <div
                  v-if="!photoUser"
                  class="relative inline-flex items-center justify-center w-8 h-8 overflow-hidden bg-gray-300 rounded-full dark:bg-gray-600"
                >
                  <span class="font-medium text-gray-600 dark:text-gray-300">
                    {{ initials }}
                  </span>
                </div>
                <!-- ELSE -->
                <img
                  v-if="photoUser"
                  class="h-8 w-8 rounded-full"
                  :src="photoUser"
                  alt=""
                />
              </button>
              <!-- USER MENU -->
              <user-menu-dropdown
                :email="email"
                :userName="userName"
                :photo="photoUser"
                :show="openMenu"
              />
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="w-screen h-screen">
      <div
        class="flex flex-wrap mx-auto max-w-7xl h-screen content-start dark:text-gray-300"
      >
        <div class="mx-auto max-h-screen">
          <h2
            class="text-2xl my-6 font-bold sm:text-3xl text-gray-900 dark:text-gray-300 p-2"
          >
            Empresas
          </h2>
          <div class="mt-4 mb-8 md:flex md:items-center md:justify-between p-2">
            <div class="relative flex items-center w-full md:w-3/4">
              <span class="absolute">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5 mx-3 text-gray-400 dark:text-gray-600"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                  />
                </svg>
              </span>
              <input
                type="text"
                v-model="filter"
                placeholder="Buscar empresa"
                class="block w-full py-1.5 pr-5 text-gray-700 bg-white border-2 border-gray-200 rounded-lg placeholder-gray-600 pl-11 rtl:pr-11 rtl:pl-5 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600 focus:border-blue-300 focus:ring-gray-600 focus:outline-none"
              />
            </div>
            <div class="flex items-center gap-3 mt-3 md:mt-0">
              <router-link
                to="/empresa"
                type="button"
                v-show="userStore.user?.userId === -1"
                aria-orientation="vertical"
                aria-labelledby="filter-button"
                tabindex="-1"
                class="flex items-center text-white text-sm px-5 py-2 transition-colors duration-300 bg-blue-500 rounded-lg hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50"
                aria-label="show filter panel"
              >
                Agregar empresa
              </router-link>
            </div>
          </div>
          <div class="container flex flex-wrap p-2 gap-5 max-h-[700px]">
            <div
              class="item h-48"
              v-for="item in filtered"
              :key="item.RFC"
              @click="companySelectionChanged(item)"
            >
              <div
                class="bg-gray-50 dark:bg-gray-800 shadow rounded-lg p-6 group dark:hover:bg-gray-700 h-48"
                :class="
                  companyStore.companySeleted.id === item.id
                    ? 'ring-4 bg-gray-200 ring-gray-500 dark:ring-gray-300 dark:bg-gray-800'
                    : ''
                "
              >
                <div class="flex items-center space-x-2">
                  <div
                    class="flex items-center justify-center w-12 h-12 bg-gray-300 rounded-lg dark:bg-gray-600"
                  >
                    <span class="text-base text-gray-600 dark:text-gray-300">
                      {{ commons.getInitialLetters(item.RFC, false) }}
                    </span>
                  </div>
                  <h4 class="font-medium text-md text-gray-500">
                    {{ item.RFC }}
                  </h4>
                </div>
                <h3
                  class="text-xl text-gray-900 dark:text-gray-200 h-auto text-ellipsis overflow-hidden"
                >
                  {{ item.Nombre }}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, defineEmits, computed } from "vue";
import { useCompanyStore } from "../stores/CompanyStore";
import { Key } from "../stores/KeyString";
import commons from "../Commons/commons";
import { RouterLink } from "vue-router";
import { useUserStore } from "../stores/UserStore";
import UserMenuDropdown from "./UserMenuDropdown.vue";
const companyStore = useCompanyStore();
const userStore = useUserStore();
const filter = ref("");
const openMenu = ref(false);
const email = ref("");
const userName = ref("");
const photoUser = ref("");
onMounted(() => {
  companyStore.getCompaniesFiltered(filter.value);
  // var res = companyStore.decodeBase64ToXML(
  //   "PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48UGVybWlzaW9ucyB4bWxuczp4c2k9Imh0dHA6Ly93d3cudzMub3JnLzIwMDEvWE1MU2NoZW1hLWluc3RhbmNlIiB4bWxuczp4c2Q9Imh0dHA6Ly93d3cudzMub3JnLzIwMDEvWE1MU2NoZW1hIiBPdGhlcnNDYXRhbG9ncz0iZmFsc2UiPjxDb21wYW5pZXMgQWRkX0VkaXQ9InRydWUiIERlbGV0ZT0iZmFsc2UiIC8+PEludm9pY2VzIEFkZF9FZGl0PSJ0cnVlIiBEZWxldGVfQ2FuY2VsPSJ0cnVlIiBEb3dubG9hZD0idHJ1ZSIgU3RhbXA9InRydWUiIE9wZW5Mb2NhdGlvbj0idHJ1ZSIgUHJldmlldz0idHJ1ZSIgRXhwb3J0PSJ0cnVlIiBFbWFpbD0idHJ1ZSIgU2hhcmU9InRydWUiIC8+PENsaWVudHMgQWRkX0VkaXQ9InRydWUiIFJlcXVlc3RzPSJ0cnVlIiBBbGxvd1dlYkFjY2Vzcz0iZmFsc2UiIERlbGV0ZT0idHJ1ZSIgLz48UHJvZHVjdHMgQWRkX0VkaXQ9InRydWUiIERlbGV0ZT0idHJ1ZSIgLz48L1Blcm1pc2lvbnM+"
  // );
  if (userStore.isAuthenticated) {
    email.value = userStore.user.email;
    userName.value = userStore.user.username;
    if (userStore.user.photo) {
      photoUser.value = userStore.user.photo;
    }
  }
});

const filtered = computed(() => {
  return companyStore.getCompaniesFiltered(filter.value);
});

const onClickOutSideUserProfileDropdown = () => (openMenu.value = false);

const initials = computed(() =>
  commons.getInitialLetters(userName.value.trim(), true)
);

const emit = defineEmits(["onCompanySelectionChanged"]);

const companySelectionChanged = (company) => {
  localStorage.setItem(Key.COMPANYSELECTED, JSON.stringify(company));
  companyStore.companySeleted = company;
  emit("onCompanySelectionChanged", company);
};
</script>

<style scoped>
.container {
  overflow-y: auto; /* Scroll vertical automático */
}

/* Elementos de la cuadrícula */
.item {
  flex: 1 1 calc(33.33% - 20px); /* 3 columnas en pantallas grandes */
  max-width: calc(33.33% - 20px);
  line-height: 80px;
}

/* Responsividad: cambia a 2 columnas en pantallas medianas */
@media (max-width: 800px) {
  .container {
    max-height: 600px;
  }
  .item {
    flex: 1 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }
}

/* Responsividad: cambia a 1 columna en pantallas pequeñas */
@media (max-width: 500px) {
  .container {
    max-height: calc(90% - 192px);
  }
  .item {
    flex: 1 1 100%;
    max-width: 100%;
  }
}
</style>
